import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DfpsButton, DfpsDataTable, DfpsDataTable1 } from 'dfps-web-lib';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { InquiryDetailsComponent } from './inquiry-details/inquiry-details.component';
import { SharedModule } from '@shared/shared.module';
import { TranslocoRootModule } from 'app/transloco-root.module';


@NgModule({
  declarations: [
    DashboardComponent,
    InquiryDetailsComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    DfpsDataTable,
    DfpsDataTable1,
    TableModule,
    DfpsButton,
    ProgressSpinnerModule,
    TranslocoRootModule
  ]
})
export class HomeModule { }
