import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DfpsCommonValidators, DfpsFormValidationDirective, DirtyCheck, SET } from 'dfps-web-lib';
import { FamilyProfileService } from '../../../services/family.service';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { SharedValidator } from '@shared/validators/shared.validator';
import { AppUtilService } from '@shared/services/app-util.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-family-member',
  templateUrl: './add-family-member.component.html',
  styleUrls: ['./add-family-member.component.css']
})

export class AddFamilyMemberComponent extends DfpsFormValidationDirective implements OnInit {

  @Input() addFamilyInfo: any;
  @Output() emitAddFamilyActions = new EventEmitter();

  addFamilyMemberForm: FormGroup;
  familyMemberLookup$: Observable<any>;

  maxDate = { year: new Date().getUTCFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }
  minDate = { year: new Date().getUTCFullYear() - 120, month: 12, day: 31 }

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private familyProfileService: FamilyProfileService,
    private appUtilService: AppUtilService,
    public store: Store<{ dirtyCheck: DirtyCheck }>,
    private datePipe: DatePipe) {
    super(store);
  }

  ngOnInit(): void {
    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        this.familyProfileService.getFamilyProfilePageLookupsES().then(data => {
          this.familyMemberLookup$ = data;
        });
      } else {
        this.familyProfileService.getFamilyProfilePageLookups().then(data => {
          this.familyProfileService.familyProfilePageLookups = data;
          this.familyMemberLookup$ = data;
        });
      }
    });

    this.createForm();
    this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
    if (this.addFamilyInfo.actionType === 'update') {
      this.patchFamilMemberData();
    }
  }

  createForm() {
    this.addFamilyMemberForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      dT_BIRTH: ['', [Validators.required, DfpsCommonValidators.validateDate, SharedValidator.validateFutureDate]],
      gender: ['', [Validators.required]],
      relation: ['', [Validators.required]],
      familyId: [this.addFamilyInfo.familyMemberDetails.familyId],
      familyMemberId: [null],
    });
  }


  patchFamilMemberData() {
    this.addFamilyMemberForm.patchValue({
      familyId: this.addFamilyInfo.familyMemberDetails.familyId,
      familyMemberId: this.addFamilyInfo.familyMemberDetails.familyMemberId,
      firstname: this.addFamilyInfo.familyMemberDetails.firstname,
      relation: this.addFamilyInfo.familyMemberDetails.relation,
      lastname: this.addFamilyInfo.familyMemberDetails.lastname,
      gender: this.addFamilyInfo.familyMemberDetails.gender,
      dT_BIRTH: this.addFamilyInfo.familyMemberDetails.dT_BIRTH ? this.datePipe.transform(this.addFamilyInfo.familyMemberDetails.dT_BIRTH, 'MM/dd/yyyy') : null
    });
  }

  onAddUpdateFamilyMember() {
    if (this.validateFormGroup(this.addFamilyMemberForm)) {
      this.familyProfileService.addUpdateFamilyMember(this.addFamilyMemberForm.value).subscribe((res) => {
        /* if (res.status === 200) {        } */
        this.activeModal.dismiss();
        this.emitAddFamilyActions.emit(true);
      });
    }
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
