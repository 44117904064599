import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogonComponent } from './logon/logon.component';
import { FamilyInfoComponent } from '../family/family-profile/family-info/family-info.component';
import { CpaAccountComponent } from '../cpa-account/cpa-account-register/cpa-account.component';
import { AccessErrorComponent } from './access-error/access-error.component';
import { RegisterComponent } from './register/register.component';

const authenticationRoutes: Routes = [
  {
    path: '', redirectTo: 'logon', pathMatch: 'full'
  },
  {
    path: 'register',
    pathMatch: 'full',
    component: RegisterComponent,
    data: { title: 'Register' }
  },
  {
    path: 'logon',
    component: LogonComponent,
    data: {
      title: 'TARE Logon', allowedRoles: [], routerLinkRole: '/logon', tabTitle: 'Logon'
    }
  },
  {
    path: 'createAccount',
    component: FamilyInfoComponent,
    data: {
      title: 'Create an Account', allowedRoles: [], routerLinkRole: '/logon', tabTitle: 'Create an Account'
    },
    canActivate: [],
  },
  {
    path: 'cpaAccountRegister',
    pathMatch: 'full',
    component: CpaAccountComponent,
    data: {
      title: 'Cpa Account', allowedRoles: [], routerLinkRole: '/cpaAccountRegister', tabTitle: 'Cpa Account'
    },
    canActivate: []
  },
  {
    path: 'accessError',
    pathMatch: 'full',
    component: AccessErrorComponent,
    data: { title: 'Access Error' }
  },

  {
    path: 'application/TAREPublic/logon',
    redirectTo: 'logon',
    pathMatch: 'full'
  },
  {
    path: 'application/TAREPublic/register',
    pathMatch: 'full',
    component: RegisterComponent,
    data: { title: 'Register' }
  }

];

@NgModule({
  imports: [RouterModule.forChild(authenticationRoutes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
