<ng-container *transloco="let inqDet">
    <div class="row">
        <h1 class="col-sm-12">{{inqDet('inquiryDetails')}}</h1>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>

    <div class="row pl-3 pt-1">
        <div class="col-12 col-sm-12 pt-2">
            <p class="col-4 col-sm-4 col-lg-2 boldBody d-inline-flex mb-0">
                <strong>{{inqDet('statusOfInquiry')}}:</strong>
            </p>
            <p class="col-8 col-sm-8 pl-0 pr-0 mb-0 d-inline-flex">{{inquiryDetailsResponse?.status}}</p>
        </div>
    </div>

    <div class="row pl-3 pt-4">
        <h2 class="col-sm-12 ">{{inqDet('childInformation')}}</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-4">
        <div class="col-lg-6 col-xl-8">
            <div class="row">
                <div class="col-12 col-xl-6 pt-3">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0">
                        <strong>{{inqDet('name')}}:</strong>
                    </p>
                    <p class="col-8 col-sm-8 pl-0 pr-0 mb-0 d-inline-flex notranslate">
                        {{inquiryDetailsResponse?.childName}}
                    </p>
                </div>
                <div class="col-12 col-xl-6 pt-2 pt-3">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0">
                        <strong>{{inqDet('nickname')}}:</strong>
                    </p>
                    <span class="notranslate">{{inquiryDetailsResponse?.nickName}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0">
                        <strong>{{inqDet('age')}}:</strong>
                    </p>
                    <span>{{inquiryDetailsResponse?.age}}</span>
                </div>
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0">
                        <strong>{{inqDet('gender')}}:</strong>
                    </p>
                    <span class="notranslate">{{inquiryDetailsResponse?.gender}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0">
                        <strong>{{inqDet('race')}}:</strong>
                    </p>
                    <p class="col-8 col-sm-8 pl-0 pr-0 mb-0 d-inline-flex notranslate">
                        {{inquiryDetailsResponse?.race}}</p>
                </div>
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0">
                        <strong>{{inqDet('ethnicity')}}:</strong>
                    </p>
                    <p class="col-8 col-sm-8 pl-0 pr-0 mb-0 d-inline-flex notranslate">
                        {{inquiryDetailsResponse?.ethnicity}}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0">
                        <strong>{{inqDet('region')}}:</strong>
                    </p>
                    <p class="col-8 col-sm-8 pl-0 pr-0 mb-0 d-inline-flex">{{inquiryDetailsResponse?.region}}</p>
                </div>
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0">
                        <strong>{{inqDet('primaryLanguage')}}:</strong>
                    </p>
                    <span class="notranslate">{{inquiryDetailsResponse?.primaryLanguage}}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>