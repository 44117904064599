import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class parentalExpectationsValidators {
    static parentalExpectationscustomReqFields(group: FormGroup) {
        const expressionEmotion = group.controls.expressionEmotion.value;
        const employerFlexibility = group.controls.employerFlexibility.value;

        if (!expressionEmotion || expressionEmotion === '' || expressionEmotion === null) {
            group.controls.expressionEmotion.setValidators(parentalExpectationsValidators.setErrorValidator({
                required: { customFieldName: 'Expression of Emotion' }
            }));
        } else { group.controls.expressionEmotion.clearValidators(); }
        if (!employerFlexibility || employerFlexibility === '' || employerFlexibility === null) {
            group.controls.employerFlexibility.setValidators(parentalExpectationsValidators.setErrorValidator({
                required: { customFieldName: 'Flexibility-Time' }
            }));
        } else { group.controls.employerFlexibility.clearValidators(); }

        group.controls.expressionEmotion.updateValueAndValidity({ onlySelf: true });
        group.controls.employerFlexibility.updateValueAndValidity({ onlySelf: true });
        return null;
    }

    static setErrorValidator(errorObject: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return errorObject;
        };
    }
}