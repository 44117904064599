import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OocFooterComponent } from './ooc-footer/ooc-footer.component';
import { OocHeaderSideNavComponent } from './ooc-header-side-nav/ooc-header-side-nav.component';



@NgModule({
  declarations: [
    OocFooterComponent,
    OocHeaderSideNavComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    OocFooterComponent,
    OocHeaderSideNavComponent
  ]
})
export class OfficeOfCommunicationModule { }
