<ng-container *transloco="let inq">
    <ng-container *ngIf="(childProfileResponse?.inquiryStatus==='Submitted') else inquiryOnChild">
        <div class="row">
            <h1 class="col-sm-12">{{inq('childInquirySubmittedHeading')}}</h1>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>
        <div class="row pl-4 pt-3">
            <p class="col-sm-12 boldBody mb-0">{{inq('inquiryReceived',{firstName:childProfileResponse?.firstName})}}
            </p>
        </div>
    </ng-container>

    <ng-template #inquiryOnChild>
        <ng-container *ngIf="!displayprofileSetupMsg else profileSetupMsg">
            <div class="row">
                <h1 class="col-sm-12">{{inq('childInquiryHeading')}} <span
                        class="notranslate">{{childProfileResponse?.firstName}}</span></h1>
                <div class="col-sm-12">
                    <hr aria-hidden="true" />
                </div>
            </div>

            <div #errors>
                <dfps-form-validation [shouldCheckDirty]="true" [validationErrors]="validationErrors"
                    [validationErrorsLength]="validationErrorsLength">
                </dfps-form-validation>
            </div>
            <form [formGroup]="inquiryForm" (ngSubmit)="submitInquiry()">
                <div class="row pl-4">
                    <div class="col-sm-12 d-flex pt-3">
                        <label for="relatedToChildOrSiblingGroup"
                            class="col-sm-5 col-md-3 col-xl-2 col-form-label requiredDisplay">{{inq('areYouRelatedToChildSibling')}}</label>
                        <dfps-select _id="relatedToChildOrSiblingGroup" (change)="onRelatedChange()"
                            formControlName="relatedToChildOrSiblingGroup"
                            [dropDownValues]="inquiryLookup$ ? inquiryLookup$['relatedTo']:[]">
                        </dfps-select>
                    </div>
                </div>
                <div class="row pl-4 pt-3">
                    <p class="col-sm-12 boldBody mb-0">{{inq('ifYouRelateToChildSibling')}}</p>
                </div>
                <ng-template [ngIf]="dspRelativeRelation">
                    <div class="row pl-4">
                        <div class="col-sm-12 d-flex pt-3">
                            <label for="relativeType"
                                class="col-sm-5 col-md-3 col-xl-2 col-form-label requiredDisplay">{{inq('relativeType')}}:</label>
                            <dfps-select _id="relativeType" formControlName="relativeType" aria-required="true"
                                [dropDownValues]="inquiryLookup$ ? inquiryLookup$['relativeType']:[]"
                                (change)="onRelativeTypeChange()">
                            </dfps-select>
                        </div>
                    </div>
                    <div class="row pl-4">
                        <div class="col-sm-12 d-flex pt-3">
                            <label for="relationship"
                                class="col-sm-5 col-md-3 col-xl-2 col-form-label requiredDisplay">{{inq('relationship')}}:</label>
                            <dfps-select _id="relationship" formControlName="relationship" aria-required="true"
                                [dropDownValues]="inquiryLookup$ ? inquiryLookup$['relationship']:[]">
                            </dfps-select>
                        </div>
                    </div>
                    <div class="row pl-4">
                        <div class="col-sm-12 d-flex pt-3">
                            <label for="relationshipDescription"
                                class="col-sm-5 col-md-3 col-xl-2 col-form-label requiredDisplay">{{inq('howAreYouRelated')}}
                            </label>
                            <dfps-textarea _id="relationshipDescription" class="col-sm-7 col-md-9 col-xl-10 p-0"
                                formControlName="relationshipDescription" name="relationshipDescription"
                                maxLength="4000" aria-required="true"
                                (focusout)="onTrimSpace('relationshipDescription')"></dfps-textarea>
                        </div>
                    </div>
                </ng-template>

                <div class="row pl-4">
                    <div class="d-flex col-sm-9 col-lg-9 col-xl-10 justify-content-center">
                        <p-progressSpinner *ngIf="requestProcessing"></p-progressSpinner>
                    </div>
                    <div class="d-flex col-sm-3 col-lg-3 col-xl-2 pt-2 justify-content-end">
                        <dfps-button type="submit" size="small" label="{{inq('submiyInquiry')}}" _id="submitInquiry"
                            [disabled]="requestProcessing"></dfps-button>
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-template #profileSetupMsg>
            <div class="row">
                <h1 class="col-sm-12">{{inq('incompleteProfile')}}</h1>
                <div class="col-sm-12">
                    <hr aria-hidden="true" />
                </div>
            </div>
            <div class="row pl-4 pt-3">
                <p class="col-sm-12 boldBody mb-0">{{inq('lowProbabilityBeingMatch')}}</p>
            </div>
            <div class="row pl-4">
                <div class="d-flex col-sm-6 pt-3 justify-content-center">
                    <dfps-button type="button" size="small" label="{{inq('yes')}}" _id="completeProfileYes"
                        ariaLabel="{{inq('finishSetupProfileYes')}}" (click)="completeProfile(true)"></dfps-button>
                    <dfps-button class="ml-4" type="button" size="small" label="{{inq('no')}}" _id="completeProfileNo"
                        ariaLabel="{{inq('finishSetupProfileNo')}}" (click)="completeProfile(false)"></dfps-button>
                </div>
            </div>
        </ng-template>
    </ng-template>
</ng-container>