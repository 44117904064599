import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { AuthTareService } from '@shared/services/auth-tare.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-logon',
  templateUrl: './logon.component.html',
  styleUrls: ['./logon.component.css']
})
export class LogonComponent implements OnInit {

  subs: Subscription[] = [];
  isUserLoggedIn: boolean;

  constructor(
    private authTareService: AuthTareService) {
  }

  ngOnInit(): void {
    this.authTareService.userLoginFlow();
  }

  login() {
    if (environment.newTareLoginPage) {
      let h = 650, w = 600;
      let left = (screen.width / 2) - (w / 2);
      let top = (screen.height / 2) - (h / 2);
      window.open('/newTareResetPassword', 'resetPassword', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
    }
    this.authTareService.login();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

}
