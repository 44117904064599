import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FamilyRoutingModule } from './family-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DfpsButton, DfpsCheckBox, DfpsDataTable, DfpsDataTable1, DfpsDataTableLazyload, DfpsDataTableLazyload1, DfpsDatePicker, DfpsDirtyCheck, DfpsFormValidation, DfpsInput, DfpsMultiSelect, DfpsProgressBar, DfpsSelect, DfpsTextarea } from 'dfps-web-lib';
import { SharedModule } from '@shared/shared.module';
import { AddFamilyMemberComponent } from './family-profile/family-info/add-family-member/add-family-member.component';
import { FamilyInfoComponent } from './family-profile/family-info/family-info.component';
import { ProfilePercentSuccessMsgComponent } from './family-profile/profile-percent-success-msg/profile-percent-success-msg.component';
import { ParentalExpectationsComponent } from './family-profile/parental-expectations/parental-expectations.component';
import { AgencyHistoryComponent } from './family-profile/agency-history/agency-history.component';
import { TableModule } from 'primeng/table';
import { AdoptionInterestsComponent } from './family-profile/adoption-interests/adoption-interests.component';
import { OrderListModule } from 'primeng/orderlist';
import { FamilyChildCharSpecialNeedsHobbiesComponent } from './family-profile/family-child-characteristic-preferences/family-child-char-special-needs-hobbies/family-child-char-special-needs-hobbies.component';
import { DynamicCharacteristicsComponent } from './family-profile/family-child-characteristic-preferences/dynamic-characteristics/dynamic-characteristics.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';


@NgModule({
  declarations: [
    FamilyInfoComponent,
    AddFamilyMemberComponent,
    ProfilePercentSuccessMsgComponent,
    ParentalExpectationsComponent,
    AgencyHistoryComponent,
    AdoptionInterestsComponent,
    FamilyChildCharSpecialNeedsHobbiesComponent,
    DynamicCharacteristicsComponent
  ],
  imports: [
    CommonModule,
    FamilyRoutingModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    DfpsButton, DfpsDirtyCheck, DfpsFormValidation, DfpsInput, DfpsTextarea,
    DfpsProgressBar,
    DfpsSelect,
    DfpsMultiSelect,
    DfpsDatePicker,
    DfpsDataTableLazyload,
    DfpsDataTableLazyload1,
    DfpsDataTable,
    DfpsDataTable1,
    TableModule,
    OrderListModule,
    DfpsCheckBox,
    ProgressSpinnerModule
  ]
})
export class FamilyModule { }
