<ng-container *transloco="let pn">
    <div class="primarynav_container mb-2">
        <ul class="nav nav-tabs firstLevelT" aria-orientation="horizontal" role="tablist">
            <ng-template [ngIf]="isRoleFamily">
                <li class="nav-item" role="presentation" *ngIf="familyId">
                    <a class="nav-link w-100" data-toggle="tab" role="tab" aria-controls="dashboard"
                        (keydown.space)="$event.preventDefault();$event.target.click()" routerLink="/home/dashboard"
                        routerLinkActive="active" [attr.aria-selected]="dashboardRla.isActive ? true : false"
                        #dashboardRla="routerLinkActive">{{pn('dashboard')}}</a>
                </li>
            </ng-template>
            <li class="nav-item" role="presentation" *ngIf="!(isUserLoggedIn && !familyId)">
                <a class="nav-link wideTab w-100" data-toggle="tab" role="tab" aria-controls="childSearch"
                    (keydown.space)="$event.preventDefault();$event.target.click()" routerLink="/child/childSearch"
                    routerLinkActive="active" [attr.aria-selected]="cildRla.isActive ? true : false"
                    #cildRla="routerLinkActive">{{pn('childSearch')}}</a>
            </li>
            <ng-template [ngIf]="isRoleFamily">
                <li class="nav-item" role="presentation" *ngIf="familyId">
                    <a class="nav-link wideTab w-100" data-toggle="tab" role="tab" aria-controls="familyProfile"
                        (keydown.space)="$event.preventDefault();$event.target.click()" routerLink="/familyProfile"
                        routerLinkActive="active" [attr.aria-selected]="familyProfilehRla.isActive ? true : false"
                        #familyProfilehRla="routerLinkActive">{{pn('familyInfo')}}</a>
                </li>
                <li class="nav-item" role="presentation" *ngIf="!familyId && isUserLoggedIn">
                    <a class="nav-link wideTab w-100" data-toggle="tab" role="tab" aria-controls="familyProfile"
                        (keydown.space)="$event.preventDefault();$event.target.click()" routerLink="/createAccount"
                        routerLinkActive="active" [attr.aria-selected]="createAccountRla.isActive ? true : false"
                        #createAccountRla="routerLinkActive">{{pn('createUser')}}</a>
                </li>
            </ng-template>
        </ul>
    </div>
</ng-container>