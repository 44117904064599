import { Directive, ElementRef, Renderer2, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[appAccessibilityPTableTare]'
})

export class AccessibilityPTableTareDirective implements AfterContentInit {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2) { }

  ngAfterContentInit() {
    setTimeout(() => {
      // Apparently, IE9 and above supports Array.forEach but not for NodeList, The DOM method document.querySelectorAll()(and a few others) return a NodeList.
      // All I had to do was to convert from nodeList to Array, and I did by this.
      // To operate on the list, e.g.using forEach(), the NodeList must first be converted to an Array.
      // I tried Array.from() to no avail as it requires ES6 or use ES6 - shim.

      this.addCaptionDiv();

      this.renderer.setAttribute(this.el.nativeElement.querySelector('table'), 'role', 'table');

      this.renderer.setAttribute(this.el.nativeElement.querySelector('table thead'), 'role', 'rowgroup');
      this.renderer.setAttribute(this.el.nativeElement.querySelector('table tbody'), 'role', 'rowgroup');

      const trList: any[] = this.el.nativeElement.querySelectorAll('table tr');
      Array.from(trList).forEach((x) => {
        this.renderer.setAttribute(x, 'role', 'row');
      });

      const thList: any[] = this.el.nativeElement.querySelectorAll('table th');
      Array.from(thList).forEach((x) => {
        this.renderer.setAttribute(x, 'scope', 'col');
        this.renderer.setAttribute(x, 'role', 'columnheader');
        this.sortingHeaders(x);
        this.ariaSortListen(x, 'click');
        this.ariaSortListen(x, 'keypress');
      });

      this.ariaCurrentPage();
      const paginateNumbersArray: any[] = this.el.nativeElement.querySelectorAll('button.p-paginator-element');
      const entriesDropDownInput: any = this.el.nativeElement.querySelector('p-paginator p-dropdown .p-dropdown .p-hidden-accessible input[type="text"]');
      if (entriesDropDownInput) {
        // const ariaLabelEntriesDropDownField = entriesDropDownInput.getAttribute('aria-label');
        this.renderer.removeAttribute(entriesDropDownInput, 'readonly');
        this.renderer.setAttribute(entriesDropDownInput, 'role', 'listbox');
        this.renderer.setAttribute(entriesDropDownInput, 'aria-label', this.el.nativeElement.querySelector('p-paginator p-dropdown .p-dropdown span.p-dropdown-label.p-inputtext').textContent
          + this.el.nativeElement.querySelector('p-paginator .p-paginator-right-content').textContent);
        /* const entriesDropDownLabelText: any = this.el.nativeElement.querySelector('p-paginator p-dropdown .p-dropdown label.p-dropdown-label');
        this.renderer.setAttribute(entriesDropDownLabelText, 'for', 'paginateList');
        const selectPageDropDown = this.el.nativeElement.querySelector('p-paginator p-dropdown .p-dropdown .p-hidden-accessible.p-dropdown-hidden-select select');
        this.renderer.setAttribute(selectPageDropDown, 'id', 'paginateList'); */

        this.paginateListBoxHasPop(entriesDropDownInput);
      }
      Array.from(paginateNumbersArray).forEach((x) => {
        this.renderer.setAttribute(x, 'role', 'button');
        const span = this.renderer.createElement('span');
        this.renderer.addClass(span, 'sr-only');
        let paginatorAriaLabel = '';
        let pageName = '';
        if (x.textContent !== '') {
          paginatorAriaLabel = 'Navigate To Page ' + x.textContent + ' in Table';
        } else if (x.textContent === '') {
          if (x.className.includes('p-paginator-first')) {
            pageName = 'First Page';
          } else if (x.className.includes('p-paginator-prev')) {
            pageName = 'Previous Page';
          } else if (x.className.includes('p-paginator-next')) {
            pageName = 'Next Page';
          } else if (x.className.includes('p-paginator-last')) {
            pageName = 'Last Page';
          }
          paginatorAriaLabel = 'Navigate To ' + pageName + ' in Table';
        }
        /* this.renderer.appendChild(span, srText);
        this.renderer.appendChild(x, span); */

        this.renderer.setAttribute(x, 'aria-label', paginatorAriaLabel);

        this.renderer.listen(this.el.nativeElement.querySelector('p-paginator'), 'click', () => {
          Array.from(paginateNumbersArray).forEach((z) => {
            this.renderer.removeAttribute(z, 'aria-current');
          });
          this.ariaCurrentPage();
          this.addFocusArialabeltoRadioButtons();
        });
      });
      this.addFocusArialabeltoRadioButtons();
    }, (1000));
  }

  addFocusArialabeltoRadioButtons() {
    const radioInputArray: any[] = this.el.nativeElement.querySelectorAll('.p-hidden-accessible input[type="radio"]');
    const radioAriaLabelText: any[] = this.el.nativeElement.querySelectorAll('span.hiddenAccessibleRadioText');
    this.radioCheckboxAriaLabelKeyboardFocus(radioInputArray, radioAriaLabelText);
    const chckBoxInputArray: any[] = this.el.nativeElement.querySelectorAll('.p-hidden-accessible input[type="checkbox"]');
    const chckBoxAriaLabelText: any[] = this.el.nativeElement.querySelectorAll('span.hiddenAccessibleCheckBoxText');
    this.radioCheckboxAriaLabelKeyboardFocus(chckBoxInputArray, chckBoxAriaLabelText);
  }

  radioCheckboxAriaLabelKeyboardFocus(inputArray: any[], ariaLabelText: any[]) {
    if (inputArray && inputArray.length > 0) {
      Array.from(inputArray).forEach((element, index) => {
        this.renderer.listen(element, 'focus', () => {
          let scrollTo: Element;
          if (element.type === 'radio') {
            scrollTo = this.el.nativeElement.querySelector('.p-radiobutton-box.p-widget.p-state-default.p-state-focus');
          } else if (element.type === 'checkbox') {
            scrollTo = this.el.nativeElement.querySelector('.p-chkbox-box.p-widget.p-state-default.p-state-focus');
          }
          scrollTo.scrollIntoView();
        });
        this.renderer.setAttribute(element, 'aria-label', ariaLabelText[index].textContent);
      });
    }
  }

  ariaCurrentPage() {
    const activePage: any = this.el.nativeElement.querySelector('p-paginator button.p-highlight');
    if (activePage) {
      this.renderer.setAttribute(activePage, 'aria-current', 'page');
    }
  }

  paginateListBoxHasPop(entriesDropDownInput) {
    // artf147734, Defect ID 14391 A11Y JAWS entries per page announcement incorrect.
    // Please see Defect attachment where it explains about JAWS issues. This is working in chrome not ie IE, IE is not able to pick dynamic update of text content.
    const showingNoofItems = this.el.nativeElement.querySelector('p-paginator .p-paginator-left-content');
    this.renderer.setAttribute(showingNoofItems, 'aria-atomic', 'true');
    this.renderer.setAttribute(showingNoofItems, 'aria-live', 'assertive');
    this.renderer.setAttribute(entriesDropDownInput, 'aria-controls', 'paginateULPopupListId');
    this.renderer.setAttribute(entriesDropDownInput, 'aria-activedescendant', 'paginateULPopupListId');

    this.renderer.listen(this.el.nativeElement.querySelector('p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-hidden-accessible input'), 'keyup', (e) => {
      // TO DO Seperate this function and add click listner, remove aria-expanded when closed.
      if (this.el.nativeElement.querySelector(
        'p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list')) {
        const eventCode = (e.keyCode ? e.keyCode : e.which);
        if (eventCode === 32) {
          this.renderer.setAttribute(entriesDropDownInput, 'aria-expanded', 'true');
          const noOfEntriesPopupListPdropdown: any[] =
            this.el.nativeElement.querySelectorAll(
              'p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list p-dropdownitem');
          Array.from(noOfEntriesPopupListPdropdown).forEach((x) => {
            this.renderer.setAttribute(x, 'aria-label', x.querySelector('li.p-dropdown-item').getAttribute('aria-label'));
          });
          this.renderer.setAttribute(this.el.nativeElement.querySelector('p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel'
            + ' .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list p-dropdownitem li.p-dropdown-item.p-state-highlight'), 'aria-selected', 'true');
          const noOfEntriesPopupListLiSelected = this.el.nativeElement.querySelector('p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel'
            + ' .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list p-dropdownitem li.p-dropdown-item.p-state-highlight');
          if (noOfEntriesPopupListLiSelected.className.includes('p-state-highlight')) {
            this.renderer.setAttribute(noOfEntriesPopupListLiSelected, 'aria-selected', 'true');
            this.renderer.setAttribute(noOfEntriesPopupListLiSelected, 'id', 'paginateULPopupListId');
            this.renderer.setAttribute(entriesDropDownInput, 'aria-label',
              noOfEntriesPopupListLiSelected.getAttribute('aria-label') + this.el.nativeElement.querySelector('p-paginator .p-paginator-right-content').textContent);
          }

        } else {
          const noOfEntriesPopupListLi: any[] =
            this.el.nativeElement.querySelectorAll('p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel'
              + ' .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list p-dropdownitem li.p-dropdown-item');
          this.renderer.listen(entriesDropDownInput, 'keyup', (event) => {
            if (this.el.nativeElement.querySelector(
              'p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list')) {
              const eventCodeType = (event.keyCode ? event.keyCode : event.which);
              if (eventCodeType === 35 || eventCodeType === 36 || eventCodeType === 38 || eventCodeType === 40) {
                Array.from(noOfEntriesPopupListLi).forEach((x) => {
                  const noOfEntriesPopupListLiSelected = this.el.nativeElement.querySelector('p-paginator .p-paginator-bottom.p-paginator p-dropdown .p-dropdown .p-dropdown-panel'
                    + ' .p-dropdown-items-wrapper ul.p-dropdown-items.p-dropdown-list p-dropdownitem li.p-dropdown-item.p-state-highlight');
                  this.renderer.removeAttribute(x, 'aria-selected');
                  this.renderer.removeAttribute(x, 'id');
                  if (noOfEntriesPopupListLiSelected.className.includes('p-state-highlight')) {
                    this.renderer.setAttribute(noOfEntriesPopupListLiSelected, 'aria-selected', 'true');
                    this.renderer.setAttribute(noOfEntriesPopupListLiSelected, 'id', 'paginateULPopupListId');
                    this.renderer.setAttribute(entriesDropDownInput, 'aria-label',
                      noOfEntriesPopupListLiSelected.getAttribute('aria-label') + this.el.nativeElement.querySelector('p-paginator .p-paginator-right-content').textContent);
                  } else {
                    this.renderer.removeAttribute(entriesDropDownInput, 'aria-expanded');
                  }
                });
              }
            }
          });
        }
      }
    });
  }

  sortingHeaders(sortTh) {
    const sortIcon: any = sortTh.querySelector('p-sorticon .p-sortable-column-icon.pi.pi-fw');
    if (sortIcon) {
      const sortUpIcon: any = sortTh.querySelector('p-sorticon .p-sortable-column-icon.pi.pi-fw.pi-sort-up');
      if (sortUpIcon) {
        this.renderer.setAttribute(sortTh, 'aria-sort', 'ascending');
        this.renderer.setAttribute(sortTh, 'aria-label', sortTh.textContent + ' Activate to sort column descending');
      } else {
        const sortDownIcon: any = sortTh.querySelector('p-sorticon .p-sortable-column-icon.pi.pi-fw.pi-sort-down');
        if (sortDownIcon) {
          this.renderer.setAttribute(sortTh, 'aria-sort', 'descending');
        } else {
          this.renderer.removeAttribute(sortTh, 'aria-sort');
        }
        this.renderer.setAttribute(sortTh, 'aria-label', sortTh.textContent + ' Activate to sort column ascending');
      }
    }
  }

  ariaSortListen(th, listenEvent) {
    this.renderer.listen(th, listenEvent, (e) => {
      const eventCode = (e.keyCode ? e.keyCode : e.which);
      if (((eventCode === 13 || eventCode === 0) && listenEvent === 'keypress') || listenEvent === 'click') {
        this.sortingHeaders(th);
        const captionElement: any = this.el.nativeElement.querySelector('.p-table .p-table-caption .captionDiv .sr-only.sortedBySpan');
        if (captionElement) {
          captionElement.textContent = 'Sorted By ' + th.textContent + th.getAttribute('aria-sort');
        }
        this.addFocusArialabeltoRadioButtons();
      }
    });
  }

  addCaptionDiv() {
    const captionDiv = this.renderer.createElement('div');
    this.renderer.setAttribute(captionDiv, 'aria-live', 'assertive');
    this.renderer.addClass(captionDiv, 'captionDiv');
    const sortedBySpan = this.renderer.createElement('span');
    this.renderer.addClass(sortedBySpan, 'sr-only');
    this.renderer.addClass(sortedBySpan, 'sortedBySpan');
    const sortedBySpanText: string = this.renderer.createText('  ');
    this.renderer.appendChild(sortedBySpan, sortedBySpanText);
    this.renderer.appendChild(captionDiv, sortedBySpan);
    this.renderer.appendChild(captionDiv, sortedBySpan);
    if (this.el.nativeElement.querySelector('.p-table .p-table-caption')) {
      this.renderer.appendChild(this.el.nativeElement.querySelector('.p-table .p-table-caption'), captionDiv);
    }
  }

}
