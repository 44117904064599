import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthTareService } from '@shared/services/auth-tare.service';

@Component({
  selector: 'app-register',
  template: ``,
})
export class RegisterComponent implements OnInit {

  constructor(private authService: MsalService,
    private authTareService: AuthTareService) { }

  ngOnInit(): void {
    if (this.authService.instance?.getAllAccounts()?.length > 0) {
      alert('User Already Signed In');
      this.authTareService.routeToUserHomePage();
      /* localStorage.clear();
      sessionStorage.clear();
      this.authService.logoutRedirect({ postLogoutRedirectUri: environment.redirectUri }); */
    } else {
      this.authTareService.login()
    }
  }

}
