<div class="container-fluid bodycolor">
    <div class="row">
        <h1 class="col-sm-12">Foster Care and Adoption Information Meetings</h1>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <form [formGroup]="infoMeetingsForm">
        <div class="row pl-3 pb-4">
            <div class="d-flex col-sm-12 pt-3">
                <label for="region" class="col-sm-4 col-md-3 col-xl-2 col-form-label col-form-label-sm">Region:
                </label>
                <div class="col-sm-6 col-md-5 col-lg-5 col-xl-3 pl-0">
                    <dfps-select _id="region" ariaLabel="Region" formControlName="region"
                        [dropDownValues]="regionLookup?.regions" (change)="onRegionChange()">
                    </dfps-select>
                </div>
            </div>
        </div>
    </form>

    <div class="row pl-2 pt-2" *ngIf="infoMeetingsForm.get('region').value">
        <div class="col-md-12 pl-2">
            <p-table #dt1 appAccessibilityPTableTare [value]="scheduledMeetingsInfo" [paginator]="true"
                [rowsPerPageOptions]="[5,10,20]" [rows]="5" [totalRecords]="scheduledInfoTotalRecords" [first]="0"
                [alwaysShowPaginator]="false" [autoLayout]="false" class="dfps-data-table-container__ptable">

                <ng-template pTemplate="header">
                    <tr>
                        <th>Loaction</th>
                        <th>Foster/Adopt Information Meetings</th>
                        <th>Attachments</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-scheduledMeetingsInfo let-columns="columns">
                    <tr>
                        <td>
                            <strong [innerHTML]="selectedRegion" style="white-space: pre-line;"></strong>
                            <div class="mt-2" style="white-space: pre-line;">{{ scheduledMeetingsInfo?.location }}
                                <strong>({{scheduledMeetingsInfo?.locationType}})</strong>
                            </div>
                            <div class="mt-2" [hidden]="scheduledMeetingsInfo?.locationType==='Virtual'"
                                [innerHTML]="countiesList"></div>
                        </td>
                        <td>
                            {{scheduledMeetingsInfo?.name}}
                            <div style="white-space: pre-line;" class="mt-2">
                                {{scheduledMeetingsInfo?.approvedStartDate | date:'EEEE, MMMM d, y, h:mm a'}}
                                - {{scheduledMeetingsInfo?.approvedEndDate | date:'EEEE, MMMM d, y, h:mm a'}}</div>
                            <div class="mt-2" [innerHTML]="scheduledMeetingsInfo?.description"></div>
                        </td>
                        <td>
                            <div *ngFor="let attachment of scheduledMeetingsInfo?.attachmentIDs">
                                <a tabindex="0"
                                    [attr.aria-label]="'Select ' + attachment?.nmFile + ' link to download the document'"
                                    (click)="downloadFile(attachment)">{{attachment?.nmFile}}</a>
                            </div>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="3"> No records found </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="paginatorleft" let-state>
                    Showing {{state.totalRecords < 1 ? 0 : state.first + 1}} to {{state.totalRecords> 0 ?
                        ((state.rows+ state.first) <= state.totalRecords ? (state.rows + state.first) :
                            state.totalRecords) : 0}} of {{state.totalRecords ? state.totalRecords : 0}} entries
                            </ng-template>
                            <ng-template pTemplate="paginatorright" let-state> Entries Per Page </ng-template>
            </p-table>
        </div>
    </div>
</div>