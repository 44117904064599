import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DfpsFormValidationDirective, DirtyCheck, FormValidationError, DfpsCommonValidators } from 'dfps-web-lib';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppInsightMonitoringService } from '@shared/services/AppInsightMonitoringService';
import { ChildSearchService } from '../../services/child.service';
import { ChildrenSearchRequest } from '../../model/child-search';
import { MsalService } from '@azure/msal-angular';
import { DataShareService } from '@shared/services/data-share.service';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { DataView } from 'primeng/dataview';
import { translate } from '@ngneat/transloco';
import { ChildrenSearchResponse } from '@shared/model/child';
import { AppUtilService } from '@shared/services/app-util.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-child-search',
  templateUrl: './child-search.component.html',
  styleUrls: [
    "../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../.././node_modules/primeng/resources/primeng.min.css",
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    '../../../../../.././node_modules/dfps-web-lib/resources/styles/css/bootstrap-glyphicons.css',
    './child-search.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ChildSearchComponent extends DfpsFormValidationDirective implements OnInit {

  isUserLoggedIn: boolean;
  childrenSearchResults: ChildrenSearchResponse;
  displaySearchResultsTable = false;
  requestProcessing: Boolean;
  selectedValue = new FormControl(1);
  validationErrors: FormValidationError[] = [];
  validationErrorsLength = 0;
  childSearchForm: FormGroup;

  columns: { field: string; header: string; filterMatchMode: string; }[];

  childSearchLookup$: Observable<any>;
  selectedRace: any[];
  prePopulateCriteriaResponse: ChildrenSearchRequest;
  preSelectedRaceValues: string[];

  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;

  DEFAULT_PAGE_SIZE = 0;
  @ViewChild('dv') dataView: DataView;
  prevPageSize = 10;
  actualPageSize = 10;
  childSearchPayload: ChildrenSearchRequest;
  totalRecords: number;

  constructor(
    private childService: ChildSearchService,
    private formBuilder: FormBuilder,
    private shareService: DataShareService,
    private toasterService: ToastrService,
    private monitoringService: AppInsightMonitoringService,
    public store: Store<{ dirtyCheck: DirtyCheck }>,
    private appUtilService: AppUtilService,
    private authService: MsalService,
    private router: Router) {
    super(store);
  }

  createForm() {
    this.childSearchForm = this.formBuilder.group({
      firstName: [''],
      tareId: [null, DfpsCommonValidators.validateId],
      groupId: ['0'],
      age: [null],
      gender: ['MF'],
      area: [null],
      ethnicity: [''],
      race: [[]],
      behavioral: [''],
      emotional: [''],
      physical: [''],
      medical: [''],
      learning: [''],
      developmental: [''],
      risk: [''],
    });
    this.initialFormValue = this.childSearchForm.value;
  }

  selectedRaceValues(event) {
    this.selectedRace = [...event];
    this.childSearchForm.patchValue({ race: this.selectedRace });
  }

  ngOnInit(): void {
    this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;

    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        this.childService.getChildSearchPageLookupsES().then(data => {
          this.childSearchLookup$ = data;
        });
      } else {
        this.childService.getChildSearchPageLookups().then(data => {
          this.childService.searchPageLookups = data;
          this.childSearchLookup$ = data;
        });
      }
    });

    this.createForm();

    if (this.isUserLoggedIn) {
      if (this.shareService.getDataFromMap('familyProfileInfo') && this.shareService.getDataFromMap('familyProfileInfo').familyId) {
        this.getPrePopulateCriteriaPatch();
      } else {
        this.router.navigate(['/createAccount']);
      }
    }

    setTimeout(() => {
      this.sortOptions = [
        { label: translate('nameZA'), value: '!firstName' },
        { label: translate('nameAZ'), value: 'firstName' }
      ];
    }, 100);
  }

  getPrePopulateCriteriaPatch() {
    this.childService.getSavedCriteria().subscribe((res) => {
      /* if (res.status === 200) {
      } */
      this.prePopulateCriteriaResponse = res;
      this.childSearchForm.patchValue({
        firstName: this.prePopulateCriteriaResponse.firstName,
        tareId: this.prePopulateCriteriaResponse.tareId,
        groupId: this.prePopulateCriteriaResponse.groupId ? this.prePopulateCriteriaResponse.groupId : '0',
        age: this.prePopulateCriteriaResponse.age,
        gender: this.prePopulateCriteriaResponse.gender ? this.prePopulateCriteriaResponse.gender : 'MF',
        area: this.prePopulateCriteriaResponse.area,
        ethnicity: this.prePopulateCriteriaResponse.ethnicity,
        race: this.prePopulateCriteriaResponse.race,
        behavioral: this.prePopulateCriteriaResponse.behavioral,
        emotional: this.prePopulateCriteriaResponse.emotional,
        physical: this.prePopulateCriteriaResponse.physical,
        medical: this.prePopulateCriteriaResponse.medical,
        learning: this.prePopulateCriteriaResponse.learning,
        developmental: this.prePopulateCriteriaResponse.developmental,
        risk: this.prePopulateCriteriaResponse.risk
      });
      this.preSelectedRaceValues = this.prePopulateCriteriaResponse.race ? this.prePopulateCriteriaResponse.race : [];
    }, error => {
      this.toasterService.error(error, 'getSavedCriteria Error');
    });
  }

  onChildSearch() {
    this.DEFAULT_PAGE_SIZE = 10;
    this.actualPageSize = this.DEFAULT_PAGE_SIZE;
    this.prevPageSize = this.DEFAULT_PAGE_SIZE;
    this.loadDataLazy(null, true);
  }

  loadDataLazy(event, onSearch) {
    if (onSearch) {
      this.childSearchPayload = {
        pageNumber: 1,
        pageSize: 10
      }
      this.dataView.first = 0;
      this.dataView.rows = 10;
    } else {
      let pgNum = this.getPageNumber(event) + 1;
      this.actualPageSize = this.dataView ? this.dataView.rows : this.DEFAULT_PAGE_SIZE;
      if (this.prevPageSize !== this.actualPageSize) {
        if (this.dataView) {
          this.dataView.first = 0;
        }
        pgNum = 1;
        this.prevPageSize = this.actualPageSize;
      }
      this.childSearchPayload = {
        pageNumber: pgNum,
        pageSize: this.actualPageSize
      }

    }

    if (this.validateFormGroup(this.childSearchForm)) {
      this.childSearchForm.value.tareId = +this.childSearchForm.value.tareId;
      if (this.DEFAULT_PAGE_SIZE > 0) {
        this.requestProcessing = true;
        this.childService.childrenSearch(this.isUserLoggedIn, Object.assign(this.childSearchPayload, this.childSearchForm.value)).subscribe((res) => {
          if (res) {
            this.childrenSearchResults = res;
            this.totalRecords = this.childrenSearchResults?.totalElements;
            this.displaySearchResultsTable = true;
            this.requestProcessing = false;
          }
        }, error => {
          this.requestProcessing = false;
          this.toasterService.error(error, 'getChildProfile Error');
        });
      }

    }
  }

  getPageNumber(event) {
    if (event) {
      return event.first / event.rows;
    }
    return 0;
  }

  // onSortChange(event) {
  //   let value = event.value;
  //   if (value.indexOf('!') === 0) {
  //     this.sortOrder = -1;
  //     this.sortField = value.substring(1, value.length);
  //   }
  //   else {
  //     this.sortOrder = 1;
  //     this.sortField = value;
  //   }
  // }

  navigateToChildSiblingProfile(hasSiblings) {
    hasSiblings ? this.router.navigate(['/child/childSearch/siblingProfile']) : this.router.navigate(['/child/childSearch/childProfile']);
  }

  onReset(): void {
    this.childSearchForm.reset(this.initialFormValue);
    this.displaySearchResultsTable = false;
    this.childrenSearchResults = {} as ChildrenSearchResponse;
    this.preSelectedRaceValues = [];
  }

}
