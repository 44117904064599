import { AfterContentInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-ooc-footer',
  templateUrl: './ooc-footer.component.html',
  styleUrls: ['./ooc-footer.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class OocFooterComponent implements OnInit, AfterContentInit {

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterContentInit() {
    const footer = document.getElementById('ft');
    $.get(`${environment.oOCstaticContentUrl}/specialty/footer.asp`).then(function (data) {
      if (!environment.production) {
        data = data.replaceAll('href="/default.asp"', `href="${environment.dfpsDomainUrl}/default.asp"`);
        data = data.replaceAll('href="/policies/"', `href="${environment.dfpsDomainUrl}/policies/"`);
        data = data.replaceAll('href="/Contact_Us/"', `href="${environment.dfpsDomainUrl}/Contact_Us/"`);
        data = data.replaceAll('href="/policies/Website/file_viewing.asp"', `href="${environment.dfpsDomainUrl}/policies/Website/file_viewing.asp"`);
      }
      footer.outerHTML = data;
    });
  }

}
