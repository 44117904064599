import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChildSearchComponent } from './child/child-search-data-view/child-search.component';
import { ChildProfileComponent } from './child/child-profile/child-profile.component';
import { InquiryComponent } from './child/inquiry/inquiry.component';
import { SiblingProfileComponent } from './child/sibling-profile/sibling-profile.component';
import { CpaSpecialNeedsComponent } from './child/cpa-special-needs/cpa-special-needs.component';
import { AuthGuard } from '@shared/guards/auth.guard';
import { UserRoles } from '@shared/enums/user-roles.enum';

const childRoutes: Routes = [
  {
    path: 'child',
    canActivate: [],
    children: [
      {
        path: '', redirectTo: 'childSearch', pathMatch: 'full'
      },
      {
        path: 'childSearch',
        children: [
          {
            path: '',
            component: ChildSearchComponent,
            data: {
              title: 'Search for Children Waiting For Adoption', allowedRoles: [], routerLinkRole: '/child/childSearch', tabTitle: 'Child Search'
            }
          },
          {
            path: 'childProfile',
            component: ChildProfileComponent,
            data: {
              title: 'Child Profile', allowedRoles: [], routerLinkRole: '/child/childSearch/childProfile', tabTitle: 'Child Profile'
            }
          },
          {
            path: 'siblingProfile',
            component: SiblingProfileComponent,
            data: {
              title: 'Sibling Profile', allowedRoles: [], routerLinkRole: '/child/childSearch/siblingProfile', tabTitle: 'Sibling Profile'
            }
          },
          {
            path: 'inquiry',
            component: InquiryComponent,
            data: {
              title: 'Inquiry', allowedRoles: [UserRoles.Family], routerLinkRole: '/child/childSearch/inquiry', tabTitle: 'Inquiry'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'cpaSpecialNeeds',
            canActivate: [AuthGuard],
            data: { allowedRoles: [UserRoles.CPA] },
            children: [
              {
                path: '', redirectTo: 'characteristics', pathMatch: 'full'
              },
              {
                path: 'characteristics',
                pathMatch: 'full',
                component: CpaSpecialNeedsComponent,
                data: {
                  title: 'Characteristics', allowedRoles: [UserRoles.CPA], routerLinkRole: '/child/childSearch/cpaSpecialNeeds/characteristics', tabTitle: 'Characteristics',
                  characteristicsType: { code: 'CH', deCode: 'Characteristics', lookupType: 'characteristics' }
                },
                canActivate: [AuthGuard]
              },
              {
                path: 'interestHobbies',
                pathMatch: 'full',
                component: CpaSpecialNeedsComponent,
                data: {
                  title: 'Interest/Hobbies', allowedRoles: [UserRoles.CPA], routerLinkRole: '/child/childSearch/cpaSpecialNeeds/interestHobbies', tabTitle: 'Interest/Hobbies',
                  characteristicsType: { code: 'HB', deCode: 'Interest/Hobbies', lookupType: 'hobbies' }
                },
                canActivate: [AuthGuard]
              }
            ]
          },]
      },
      {
        path: 'childProfile',
        component: ChildProfileComponent,
        data: {
          title: 'Child Profile', allowedRoles: [], routerLinkRole: '/child/childProfile', tabTitle: 'Child Profile'
        }
      },
      {
        path: 'siblingProfile',
        component: SiblingProfileComponent,
        data: {
          title: 'Sibling Profile', allowedRoles: [], routerLinkRole: '/child/siblingProfile', tabTitle: 'Sibling Profile'
        }
      },
    ]
  },

  {
    path: 'application/TAREPublic/child/childSearch',
    redirectTo: 'child/childSearch',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forChild(childRoutes)],
  exports: [RouterModule]
})
export class ChildRoutingModule { }
