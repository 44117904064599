import { Injectable } from '@angular/core';
import { ErrorHandler } from '@angular/core';
 import { ToastrService } from 'ngx-toastr';
import { AppInsightMonitoringService } from './AppInsightMonitoringService';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  constructor(
    private myMonitoringService: AppInsightMonitoringService,
     // private toasterService: ToastrService
  ) {
    super();
  }

  handleError(error: Error) {
    console.log("Error" + error);
     // this.toasterService.error(error?.message, 'Error')
    this.myMonitoringService.logException(error, 1);
  }
}
