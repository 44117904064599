import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DfpsFormValidationDirective, DirtyCheck, FormValidationError, SET } from 'dfps-web-lib';
import { FamilyProfileService } from '../../../services/family.service';
import { ToastrService } from 'ngx-toastr';
import { AppUtilService } from '@shared/services/app-util.service';
import { DataShareService } from '@shared/services/data-share.service';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DynamicCharacteristicsValidators } from './dynamic-characteristics.validator';

@Component({
  selector: 'app-dynamic-characteristics',
  templateUrl: './dynamic-characteristics.component.html',
  styleUrls: [
    "../../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../../.././node_modules/ngx-bootstrap/datepicker/bs-datepicker.css",
    "../../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    "../../../../../../.././node_modules/primeng/resources/primeng.min.css",
    '../../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './dynamic-characteristics.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DynamicCharacteristicsComponent extends DfpsFormValidationDirective implements OnInit {

  @ViewChild('errors', { static: false }) errorElement: ElementRef;

  characteristicType: any;
  individualCharacteristicDetails: any[];
  validationErrors: FormValidationError[] = [];
  validationErrorsLength = 0;
  childCharacteristicPreferencesForm: FormGroup;
  requestProcessing: Boolean;
  childCharacteristicPreferencesDetails: any;
  childCharacteristicPreferencesLookup$: Observable<any>;
  familyProfileSuccessMsg: string;
  familyProfileInfo: any;

  constructor(
    private familyProfileService: FamilyProfileService,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private appUtilService: AppUtilService,
    private shareService: DataShareService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public store: Store<{ dirtyCheck: DirtyCheck }>) {
    super(store);
  }

  ngOnInit(): void {
    this.characteristicType = this.route.snapshot.data['characteristicsType'];
    this.familyProfileInfo = this.shareService.getDataFromMap('familyProfileInfo');
    this.appUtilService.isGoogleTranslateSpanish$$.subscribe(value => {
      if (value) {
        this.familyProfileService.getChildCharacteristicPreferencesLookupsES().then(data => {
          this.childCharacteristicPreferencesLookup$ = data;
          this.createForm();
          this.patchChildCharacteristicPreferences();
        });
      } else {
        this.familyProfileService.getChildCharacteristicPreferencesLookups().then(data => {
          this.familyProfileService.childCharacteristicPreferencesLookup = data;
          this.childCharacteristicPreferencesLookup$ = data;
          this.createForm();
          this.patchChildCharacteristicPreferences();
        });
      }
    });
  }

  createForm() {
    this.childCharacteristicPreferencesForm = this.formBuilder.group({
      familyId: [this.familyProfileInfo ? this.familyProfileInfo.familyId : null],
      characteristicType: [''],
      characteristicName: [''],
      characteristicDetails: this.formBuilder.array([]),
    });
    if (this.characteristicType.code != 'HB') {
      this.childCharacteristicPreferencesForm.addControl('overallRating', new FormControl(''));
      this.childCharacteristicPreferencesForm.addControl('summaryInformation', new FormControl(''));
    }
  }

  patchChildCharacteristicPreferences() {
    this.requestProcessing = true;
    this.familyProfileService.getFamilyProfileByEmail().subscribe((res) => {
      if (res && (res != null)) {
        this.childCharacteristicPreferencesDetails = res;
        const detailsResponse: any = this.childCharacteristicPreferencesDetails;
        this.childCharacteristicPreferencesForm.patchValue({
          familyId: detailsResponse.familyId, characteristicType: this.characteristicType.code, characteristicName: this.characteristicType.deCode,
        });
        this.individualCharacteristicDetails = this.childCharacteristicPreferencesLookup$[this.characteristicType.lookupType];
        if (detailsResponse.childCharacteristics.length > 0) {
          detailsResponse.childCharacteristics.forEach((obj) => {
            if ((obj.characteristicType === this.characteristicType.code) && obj.characteristicDetails.length > 0) {
              if (this.characteristicType.code != 'HB') {
                this.childCharacteristicPreferencesForm.patchValue({ overallRating: obj?.overallRating, summaryInformation: obj?.summaryInformation });
              }
              this.individualCharacteristicDetails = this.individualCharacteristicDetails.map(item => ({
                ...item, severity: obj.characteristicDetails.find(secondItem => secondItem?.childCharacteristicPreferences === item?.code)?.severity
              }));
              return;
            }
          });
        }
        const control = this.childCharacteristicPreferencesForm.controls['characteristicDetails'] as FormArray;
        this.individualCharacteristicDetails.forEach(x => { control.push(this.patchCharacteristics(x)); });
        this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
        this.requestProcessing = false;
      }
    },
      error => {
        this.requestProcessing = false;
        this.toasterService.error(error.error.message, 'getChildCharacteristicPref Error');
      });
      const overallRating = this.childCharacteristicPreferencesForm.get('overallRating');
      if(overallRating) this.childCharacteristicPreferencesForm.setValidators(DynamicCharacteristicsValidators.validateOverallRequiredFields);
  }

  patchCharacteristics(x) {
    return this.formBuilder.group({
      childCharacteristicPreferencesLabel: x.childCharacteristicPreferencesLabel ? x.childCharacteristicPreferencesLabel : x.decode,
      childCharacteristicPreferences: x.childCharacteristicPreferences ? x.childCharacteristicPreferences : x.code,
      severity: x.severity ? ((this.characteristicType.code != 'HB') ? x.severity : ((x.severity === 'Y') ? [true] : false)) : ((this.characteristicType.code != 'HB') ? 'NE' : false)
    });
  }

  onSaveChildCharacteristicPreferences() {
    if (this.validateFormGroup(this.childCharacteristicPreferencesForm)) {
      this.requestProcessing = true;
      if (this.characteristicType.code === 'HB') {
        const characteristicDetailsValue = this.childCharacteristicPreferencesForm.value.characteristicDetails;
        characteristicDetailsValue.forEach((x) => { return x.severity ? x.severity = 'Y' : x.severity = 'N'; });
      }
      this.familyProfileService.saveChildCharacteristicPreferences([this.childCharacteristicPreferencesForm.value]).subscribe((res) => {
        if (res) {
          this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
          this.familyProfileSuccessMsg = this.datePipe.transform(Date.now(), 'EEEE, MMMM d, y, h:mm:ss a');
          this.shareService.addDataToMap('familyProfileSuccessMsg', this.familyProfileSuccessMsg);
          this.store.dispatch(SET({ dirtyCheck: { isDirty: false } }));
          this.ngOnInit();
        }
        this.requestProcessing = false;
        window.scrollTo(0, 0);
      }, error => {
        this.requestProcessing = false;
        this.toasterService.error(error, 'saveChildCharacteristicPreferences Error');
      });
    } else {
      if (this.errorElement) {
        // for focussing errors.
        setTimeout(() => {
          this.errorElement.nativeElement.querySelector('#dfpsFormValidationDispayDiv').focus();
        }, 1000);
      }
    }
  }

  onTrimSpace(formControl) {
    this.appUtilService.onTrimSpace(this.childCharacteristicPreferencesForm, formControl);
  }

}
