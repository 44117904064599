import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-family-child-char-special-needs-hobbies',
  templateUrl: './family-child-char-special-needs-hobbies.component.html',
  styleUrls: [
    "../../../../../../.././node_modules/bootstrap/dist/css/bootstrap.min.css",
    "../../../../../../.././node_modules/font-awesome/css/font-awesome.css",
    "../../../../../../.././node_modules/primeng/resources/themes/saga-blue/theme.css",
    '../../../../../../.././node_modules/dfps-web-lib/resources/styles/styles.css',
    './family-child-char-special-needs-hobbies.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class FamilyChildCharSpecialNeedsHobbiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
