import { DatePipe } from '@angular/common';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CamelCasePipe, DfpsCommonValidators } from 'dfps-web-lib';

export class SharedValidator {

    static numberOnly(event: any) {
        return (event.charCode === 13 || (event.charCode >= 48 && event.charCode <= 57));
    }

    static validateNumberOnly(event: any) {
        if (!SharedValidator.numberOnly(event)) {
            event.preventDefault();
        }
    }

    static pasteNumberOnly(event: any) {
        this.pasteCommonHandling(event, /[^0-9]/g);
    }

    static pasteCommonHandling(event: any, regex) {
        if (typeof event.clipboardData === 'undefined' && window['clipboardData'] && window['clipboardData'].getData) { // IE
            if (regex.test(window['clipboardData'].getData('Text'))) {
                event.preventDefault();
            }
        } else if (typeof event.clipboardData !== 'undefined' && event.clipboardData && event.clipboardData.getData) { // other browsers
            if (regex.test(event.clipboardData.getData('text/plain'))) {
                event.preventDefault();
            }
        }
    }

    static phoneNumberExtPattern(control: AbstractControl): ValidationErrors | null {
        const NUMBER_PATTERN = /^[0-9]+$/;
        if (control.value && control.value?.toString()?.trim()?.length > 0 && !NUMBER_PATTERN.test(control.value)) {
            return {
                validatePhoneExt: {
                    actualValue: control.value,
                    customFieldName: 'Ext'
                }
            };

        }
        return null;
    }

    static phoneNumberPattern(control: AbstractControl) {
        const PHONE_NUMBER_PATTERN_TEN_DIGIT = /^[0-9]{10}$/;
        if (control.value && control.value?.toString()?.trim()?.length > 0 && !PHONE_NUMBER_PATTERN_TEN_DIGIT.test(control.value.toString().trim().replace(/[^+\d]+/g, ''))) {
            const formGroup = control.parent.controls;
            const controlName = Object.keys(formGroup).find(name => control === formGroup[name]);
            return {
                phoneNumberPattern: {
                    customFieldName: new CamelCasePipe().transform(controlName) || 'Phone',
                    csliValidationMsg: true,
                    actualValue: control.value,
                }
            };
        }
        return null;
    }

    static compareDates(from: string, to: string) {
        // cannot export lambda function without some assignment statement in a library
        // ..so just adding an unused assignment line as a workaround
        const lambdaBugInLib = 'lambdaBugInLib';
        return (group: FormGroup): { [key: string]: any } => {
            const fromDate = group.controls[from];
            const toDate = group.controls[to];
            const fromDateValidation = DfpsCommonValidators.validateDate(fromDate);
            if (fromDateValidation === null) {
                if (!fromDate.value || !toDate.value) {
                    fromDate.setErrors(null);
                    return null;
                }
                if (new Date(fromDate.value) > new Date(toDate.value)) {
                    fromDate.setErrors({ [from]: { [from]: true } });
                } else {
                    fromDate.setErrors(null);
                }
            } else {
                return fromDateValidation;
            }
            return null;
        };
    }

    static validateFutureDate(control: AbstractControl): ValidationErrors | null {
        if (!DfpsCommonValidators.validateDate(control) && new Date(control.value) > new Date()) {
            return {
                validateFutureDate: true,
            };
        } else {
            return null;
        }
    }

    static validateEmailAddress(control: AbstractControl): ValidationErrors | null {
        const EMAIL_PATTERN = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        if (control.value && !EMAIL_PATTERN.test(control.value)) {
            return {
                validateEmailAddress: {
                    actualValue: control.value,
                }
            };
        } else {
            return null;
        }
    }

    static requiredFieldonSaveandSubmit(errorKey: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return {
                    [`${errorKey}`]: true
                }
            }
            return null;
        };
    }
    static requiredFieldWithHiddenFieldName(errorKey: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return {
                    [`${errorKey}`]: {
                        hideFieldName: true
                    }
                }
            }
            return null;
        };
    }
}
