// import { ContractHeaderComponent } from './modules/financial/contract/contract-header/contract-header.component';
import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { DfpsUnauthorizedComponent } from 'dfps-web-lib';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const APP_ROUTES: Routes = [
    {
        path: 'unauthorized',
        component: DfpsUnauthorizedComponent,
    },
    /* {
        path: 'externalRedirect',
        component: ContractHeaderComponent,
        canActivate: [externalUrlProvider]
    }, */
    { path: '**', component: PageNotFoundComponent, }
];

@NgModule({
    imports: [RouterModule.forRoot(APP_ROUTES)],
    exports: [RouterModule],
    providers: [
        {
            provide: externalUrlProvider,
            useValue: (route: ActivatedRouteSnapshot) => {
                const externalUrl = route.paramMap.get('externalUrl');
                window.open(externalUrl, '_self');
            },
        }
    ],
})
export class AppRoutingModule { }