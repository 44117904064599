import { Directive, ElementRef, Renderer2, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[appAccessibilityPOrderList]'
})
export class AccessibilityPOrderListDirective implements AfterContentInit {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2) { }

  ngAfterContentInit() {
    setTimeout(() => {
      const pOrderListButtons: any[] = this.el.nativeElement.querySelectorAll('.p-orderlist-controls button');
      Array.from(pOrderListButtons).forEach((x) => {
        this.renderer.setAttribute(x, 'role', 'button');
        let buttonName = '';
        if (x.textContent.trim() !== '') {
          buttonName = x.textContent;
        } else if (x.textContent.trim() === '') {
          if (x.children[0].className.includes('pi-angle-up')) {
            buttonName = 'Move Top';
          } else if (x.children[0].className.includes('pi-angle-double-up')) {
            buttonName = 'Move Up';
          } else if (x.children[0].className.includes('pi-angle-down')) {
            buttonName = 'Move Down';
          } else if (x.children[0].className.includes('pi-angle-double-down')) {
            buttonName = 'Move Bottom';
          }
        }
        this.renderer.setAttribute(x, 'aria-label', buttonName);
      });
    }, (1000));
  }
}
