import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FamilyInfoComponent } from './family-profile/family-info/family-info.component';
import { ParentalExpectationsComponent } from './family-profile/parental-expectations/parental-expectations.component';
import { AgencyHistoryComponent } from './family-profile/agency-history/agency-history.component';
import { AdoptionInterestsComponent } from './family-profile/adoption-interests/adoption-interests.component';
import { DynamicCharacteristicsComponent } from './family-profile/family-child-characteristic-preferences/dynamic-characteristics/dynamic-characteristics.component';
import { DfpsDirtyCheckComponent } from 'dfps-web-lib';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { AuthGuard } from '@shared/guards/auth.guard';

const familyRoutes: Routes = [
  {
    path: 'familyProfile',
    canActivate: [AuthGuard],
    data: { allowedRoles: [UserRoles.Family] },
    children: [
      {
        path: '', redirectTo: 'familyInfo', pathMatch: 'full', data: { allowedRoles: [UserRoles.Family] }
      },
      {
        path: 'familyInfo',
        pathMatch: 'full',
        component: FamilyInfoComponent,
        data: {
          title: 'Family Profile', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/familyInfo', tabTitle: 'Family Profile'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'parentalExpectations',
        pathMatch: 'full',
        component: ParentalExpectationsComponent,
        data: {
          title: 'Parental Expectations', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/parentalExpectations', tabTitle: 'Parental Expectations'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'adoptionInterests',
        pathMatch: 'full',
        component: AdoptionInterestsComponent,
        data: {
          title: 'Adoption Interests', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/adoptionInterests', tabTitle: 'Adoption Interests'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'agencyHistory',
        pathMatch: 'full',
        component: AgencyHistoryComponent,
        data: {
          title: 'Agency History', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/agencyHistory', tabTitle: 'Agency History'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'familyChildCharacteristicPreferences',
        canActivate: [AuthGuard],
        data: { allowedRoles: [UserRoles.Family] },
        children: [
          {
            path: '', redirectTo: 'behavioral', pathMatch: 'full', data: { allowedRoles: [UserRoles.Family] }
          },
          {
            path: 'behavioral',
            pathMatch: 'full',
            component: DynamicCharacteristicsComponent,
            data: {
              title: 'Behavioral', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/familyChildCharacteristicPreferences/behavioral', tabTitle: 'Behavioral',
              characteristicsType: { code: 'BV', deCode: 'Behavioral', lookupType: 'behavioral' }
            },
            canActivate: [AuthGuard],
            canDeactivate: [DfpsDirtyCheckComponent]
          },
          {
            path: 'emotional',
            pathMatch: 'full',
            component: DynamicCharacteristicsComponent,
            data: {
              title: 'Emotional', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/familyChildCharacteristicPreferences/emotional', tabTitle: 'Emotional',
              characteristicsType: { code: 'EM', deCode: 'Emotional', lookupType: 'emotional' }
            },
            canActivate: [AuthGuard],
            canDeactivate: [DfpsDirtyCheckComponent]
          },
          {
            path: 'physical',
            pathMatch: 'full',
            component: DynamicCharacteristicsComponent,
            data: {
              title: 'Physical', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/familyChildCharacteristicPreferences/physical', tabTitle: 'Physical',
              characteristicsType: { code: 'PH', deCode: 'Physical', lookupType: 'physical' }
            },
            canActivate: [AuthGuard],
            canDeactivate: [DfpsDirtyCheckComponent]
          },
          {
            path: 'medical',
            pathMatch: 'full',
            component: DynamicCharacteristicsComponent,
            data: {
              title: 'Medical', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/familyChildCharacteristicPreferences/medical', tabTitle: 'Medical',
              characteristicsType: { code: 'MD', deCode: 'Medical', lookupType: 'medical' }
            },
            canActivate: [AuthGuard],
            canDeactivate: [DfpsDirtyCheckComponent]
          },
          {
            path: 'developmental',
            pathMatch: 'full',
            component: DynamicCharacteristicsComponent,
            data: {
              title: 'Developmental', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/familyChildCharacteristicPreferences/developmental', tabTitle: 'Developmental',
              characteristicsType: { code: 'DV', deCode: 'Developmental', lookupType: 'developmental' }
            },
            canActivate: [AuthGuard],
            canDeactivate: [DfpsDirtyCheckComponent]
          },
          {
            path: 'learning',
            pathMatch: 'full',
            component: DynamicCharacteristicsComponent,
            data: {
              title: 'Learning', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/familyChildCharacteristicPreferences/learning', tabTitle: 'Learning',
              characteristicsType: { code: 'LN', deCode: 'Learning', lookupType: 'learning' }
            },
            canActivate: [AuthGuard],
            canDeactivate: [DfpsDirtyCheckComponent]
          },
          {
            path: 'riskFactor',
            pathMatch: 'full',
            component: DynamicCharacteristicsComponent,
            data: {
              title: 'Risk Factor', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/familyChildCharacteristicPreferences/riskFactor', tabTitle: 'Risk Factor',
              characteristicsType: { code: 'RF', deCode: 'Risk Factor', lookupType: 'riskFactor' }
            },
            canActivate: [AuthGuard],
            canDeactivate: [DfpsDirtyCheckComponent]
          },
          {
            path: 'interestHobbies',
            pathMatch: 'full',
            component: DynamicCharacteristicsComponent,
            data: {
              title: 'Interest/Hobbies', allowedRoles: [UserRoles.Family], routerLinkRole: '/familyProfile/familyChildCharacteristicPreferences/interestHobbies', tabTitle: 'Interest/Hobbies',
              characteristicsType: { code: 'HB', deCode: 'Interest/Hobbies', lookupType: 'hobbies' }
            },
            canActivate: [AuthGuard],
            canDeactivate: [DfpsDirtyCheckComponent]
          },
        ]
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(familyRoutes)],
  exports: [RouterModule]
})
export class FamilyRoutingModule { }
