<ul class="nav flex-md-column nav-pills secondLevelP mr-0" id="v-pills-tab" role="tablist" aria-orientation="vertical"
    *ngIf="showSideNavFor=='familyProfile'">
    <li class="nav-item mb-2 ml-2" role="presentation">
        <a class="nav-link px-sm-1" id="v-pills-secondLevel-list-tab" data-toggle="pill"
            (keydown.space)="$event.preventDefault();$event.target.click()" routerLink="/familyProfile/familyInfo"
            routerLinkActive="active" role="tab" #familyInfoRla="routerLinkActive"
            aria-controls="v-pills-secondLevel-list" [attr.aria-selected]="familyInfoRla.isActive ? true : false">
            Family Information</a>
    </li>
    <ng-template [ngIf]="familyId">
        <li class="nav-item mb-2 ml-2" role="presentation">
            <a class="nav-link px-sm-1" id="v-pills-secondLevel-list-tab1" data-toggle="pill"
                (keydown.space)="$event.preventDefault();$event.target.click()"
                routerLink="/familyProfile/parentalExpectations" routerLinkActive="active" role="tab"
                aria-controls="v-pills-secondLevel-list" #parentalExpectRla="routerLinkActive"
                [attr.aria-selected]="parentalExpectRla.isActive ? true : false"> Parental Expectations</a>
        </li>
        <li class="nav-item mb-2 ml-2" role="presentation">
            <a class="nav-link px-sm-1" id="v-pills-secondLevel-list-tab2" data-toggle="pill"
                (keydown.space)="$event.preventDefault();$event.target.click()"
                routerLink="/familyProfile/adoptionInterests" routerLinkActive="active" role="tab"
                #adoptIntrest="routerLinkActive" aria-controls="v-pills-secondLevel-list"
                [attr.aria-selected]="adoptIntrest.isActive ? true : false"> Adoption Interests</a>
        </li>
        <li class="nav-item mb-2 ml-2" role="presentation">
            <a class="nav-link px-sm-1" id="v-pills-secondLevel-list-tab4" data-toggle="pill"
                (keydown.space)="$event.preventDefault();$event.target.click()"
                routerLink="/familyProfile/familyChildCharacteristicPreferences" routerLinkActive="active" role="tab"
                #childCharPrefRla="routerLinkActive" aria-controls="v-pills-secondLevel-list"
                [attr.aria-selected]="childCharPrefRla.isActive ? true : false"> Child Characteristic Preferences</a>
        </li>
        <li class="nav-item mb-2 ml-2" role="presentation">
            <a class="nav-link px-sm-1" id="v-pills-secondLevel-list-tab3" data-toggle="pill"
                (keydown.space)="$event.preventDefault();$event.target.click()"
                routerLink="/familyProfile/agencyHistory" routerLinkActive="active" role="tab"
                #agencyHistoryRla="routerLinkActive" aria-controls="v-pills-secondLevel-list"
                [attr.aria-selected]="agencyHistoryRla.isActive ? true : false">
                Agency</a>
        </li>
    </ng-template>
</ul>

<ul class="nav flex-md-column nav-pills secondLevelP mr-0" id="v-pills-tab" role="tablist" aria-orientation="vertical"
    *ngIf="showSideNavFor=='childProfile'">
    <li class="nav-item mb-2 ml-2" role="presentation">
        <a class="nav-link px-sm-1" id="v-pills-secondLevel-list-tab" data-toggle="pill"
            (keydown.space)="$event.preventDefault();$event.target.click()" aria-controls="v-pills-secondLevel-list"
            routerLink="child/childSearch/childProfile" routerLinkActive="active" role="tab"
            [attr.aria-selected]="childrenSearchPageRla.isActive ? true : false"
            #childrenSearchPageRla="routerLinkActive" [queryParams]="{id:profileId}">Information</a>
    </li>
    <li class="nav-item mb-2 ml-2" role="presentation" *ngIf="isRoleCPA">
        <a class="nav-link px-sm-1" id="v-pills-secondLevel-list-tab" data-toggle="pill"
            (keydown.space)="$event.preventDefault();$event.target.click()" aria-controls="v-pills-secondLevel-list"
            routerLink="child/childSearch/cpaSpecialNeeds" routerLinkActive="active" role="tab"
            [attr.aria-selected]="specialNeedsRla.isActive ? true : false" #specialNeedsRla="routerLinkActive"
            [queryParams]="{id:profileId}">Special Needs/Interest</a>
    </li>
</ul>