import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SharedValidator } from '@shared/validators/shared.validator';

export class CpaAccountRegisterValidators {
    static validatePrimaryPhoneRequiredField(group: FormGroup) {
        const primaryPhone = group.controls.primaryPhone.value;
        if (!primaryPhone || primaryPhone === '' || primaryPhone === null) {
            group.controls.primaryPhone.setValidators(CpaAccountRegisterValidators.setErrorValidator({
                required: { customFieldName: 'Your Phone Number' }
            }));
        } else if (primaryPhone && SharedValidator.phoneNumberPattern(group.controls.primaryPhone)) {
            group.controls.primaryPhone.setValidators(CpaAccountRegisterValidators.setErrorValidator({
                phoneNumberPattern: { customFieldName: 'Your Phone Number', actualValue: primaryPhone }
            }))

        }
        else { group.controls.primaryPhone.clearValidators(); }

        group.controls.primaryPhone.updateValueAndValidity({ onlySelf: true });
        return null;
    }

    static setErrorValidator(errorObject: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return errorObject;
        };
    }
}