import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InquiryDetailsComponent } from './inquiry-details/inquiry-details.component';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { AuthGuard } from '@shared/guards/auth.guard';

const homeRoutes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    data: { allowedRoles: [UserRoles.Family] },
    children: [
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'full', data: { allowedRoles: [UserRoles.Family] }
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        data: { allowedRoles: [UserRoles.Family] },
        children: [
          {
            path: '',
            component: DashboardComponent,
            data: {
              title: 'Dashboard', allowedRoles: [UserRoles.Family], routerLinkRole: '/dashboard', tabTitle: 'Dashboard'
            },
            canActivate: [AuthGuard]
          },
          {
            path: 'inquiryDetails',
            component: InquiryDetailsComponent,
            data: {
              title: 'Inquiry Details', allowedRoles: [UserRoles.Family], routerLinkRole: '/home/dashboard/inquiryDetails', tabTitle: 'Inquiry Details'
            },
            canActivate: [AuthGuard]
          },
        ]
      },
    ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(homeRoutes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
