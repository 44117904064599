<app-profile-percent-success-msg [familyProfileDetails]="agencyHistoryDetails"
    [successMsg]="familyProfileSuccessMsg"></app-profile-percent-success-msg>

<div #errors>
    <dfps-form-validation [shouldCheckDirty]="true" [validationErrors]="validationErrors"
        [validationErrorsLength]="validationErrorsLength">
    </dfps-form-validation>
</div>

<div class="d-flex col-sm-12 justify-content-center">
    <p-progressSpinner *ngIf="!renderFamilyProfile"></p-progressSpinner>
</div>

<form [formGroup]="agencyHistoryForm" (ngSubmit)="onSaveAgencyHistory()">
    <div class="row pl-3 pt-4">
        <h2 class="col-sm-12 ">Family Agency History</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-4 pt-3">
        <div class="col-sm-12 d-flex">
            <label for="indActiveAgency" class="col-md-10 col-xl-7 col-form-label col-form-label-sm requiredDisplay">Are
                you currently working with an agency to open your home to Foster and/or Adopt a child?</label>
            <dfps-select _id="indActiveAgency" formControlName="indActiveAgency" aria-required="true"
                ariaLabel="Are you currently working with an agency to open your home to Foster and/or Adopt a child?"
                [dropDownValues]="familyProfilePageLookups$ ? familyProfilePageLookups$['indActiveAgency']:[]"
                (change)="onWorkingWithAgencyChange('onChangeFromDropDown')">
            </dfps-select>
        </div>
    </div>

    <ng-container *ngIf="dspAgencyWorkerInfo">
        <div class="row pl-3 pt-4">
            <h2 class="col-sm-12 ">Agency Information</h2>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>

        <div class="row pl-4 pt-3">
            <p class="col-sm-12 boldBody d-inline-flex mb-0"> If you are currently working with an agency to open
                your home to Foster and/or Adopt a Child please fill out the form below. </p>
        </div>

        <div class="row pl-4 pt-4">
            <p class="col-sm-12 boldBody d-inline-flex mb-0"> All required information in this section must be
                filled out. </p>
        </div>

        <div class="row pl-5 pt-3">
            <div class="col-sm-12 col-lg-10 d-flex">
                <label for="approvedStartDate"
                    class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">Approved From: </label>
                <dfps-date-picker _id="approvedStartDate" formControlName="approvedStartDate" name="Approved From"
                    aria-required="true" ariaLabel="Approved From" [maxDate]="maxDate"
                    [minDate]="minDate"></dfps-date-picker>
            </div>
        </div>
        <div class="row pl-5 pt-3">
            <div class="col-sm-12 col-lg-10 d-flex">
                <label for="approvedEndDate" class="col-sm-3 col-form-label col-form-label-sm">Approved To: </label>
                <dfps-date-picker _id="approvedEndDate" formControlName="approvedEndDate" name="Approved To"
                    ariaLabel="Approved To" [maxDate]="maxDate" [minDate]="minDate"></dfps-date-picker>
            </div>
        </div>
        <div class="row pl-5 pt-3">
            <div class="col-sm-12 col-lg-10 d-flex">
                <label for="agencyName" class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">Agency Name:
                </label>
                <dfps-input _id="agencyName" ariaLabel="Agency Name" formControlName="agencyName" aria-required="true"
                    maxlength="30">
                </dfps-input>
            </div>
        </div>
        <div class="row pl-5 pt-3">
            <div class="col-sm-12 col-lg-10 d-flex">
                <label for="agencyCity" class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">City:</label>
                <dfps-input _id="agencyCity" ariaLabel="City" formControlName="agencyCity" aria-required="true"
                    maxlength="20">
                </dfps-input>
            </div>
        </div>
        <div class="row pl-5 pt-3">
            <div class="col-sm-12 col-lg-10 d-flex">
                <label for="agencyState"
                    class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">State:</label>
                <dfps-select _id="agencyState" ariaLabel="State" formControlName="agencyState" aria-required="true"
                    [dropDownValues]="familyProfilePageLookups$ ? familyProfilePageLookups$['state']:[]">
                </dfps-select>
            </div>
        </div>

        <div class="row pl-3 pt-4">
            <h2 class="col-sm-12 ">Case Manager/Worker Information</h2>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>

        <div class="row pl-4 pt-3">
            <p class="col-sm-12 boldBody d-inline-flex mb-0"> Please enter your caseworker's correct phone number
                and email. This will facilitate our communication with them regarding your family. </p>
        </div>

        <div class="row pl-5 pt-3">
            <div class="col-sm-12 col-lg-10 d-flex">
                <label for="caseManagerFirstName" class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">
                    First Name: </label>
                <dfps-input class="notranslate" _id="caseManagerFirstName" ariaLabel="First Name"
                    formControlName="caseManagerFirstName" aria-required="true" maxlength="22">
                </dfps-input>
            </div>
        </div>
        <div class="row pl-5 pt-3">
            <div class="col-sm-12 col-lg-10 d-flex">
                <label for="caseManagerLastName" class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">
                    Last Name: </label>
                <dfps-input class="notranslate" _id="caseManagerLastName" ariaLabel="Last Name"
                    formControlName="caseManagerLastName" aria-required="true" maxlength="22">
                </dfps-input>
            </div>
        </div>
        <div class="row pl-5 pl-5">
            <div class="col-sm-12 col-lg-10 d-flex pt-3">
                <label for="caseManagerEmail" class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">Email
                    Address:
                </label>
                <dfps-input _id="caseManagerEmail" ariaLabel="Email Address" formControlName="caseManagerEmail"
                    aria-required="true" maxlength="128">
                </dfps-input>
            </div>
        </div>
        <div class="row pl-5 pl-5">
            <div class="col-sm-12 col-lg-10 d-flex pt-3">
                <label for="caseManagerPhone" class="col-sm-3 col-form-label col-form-label-sm requiredDisplay">
                    Phone Number: </label>
                <input appPhoneMask id="caseManagerPhone" aria-label="Phone Number" formControlName="caseManagerPhone"
                    aria-required="true" maxlength="14">
            </div>
        </div>

        <div class="row pl-3 pt-4">
            <h2 class="col-sm-12 ">Comments </h2>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>

        <div class="row pl-5 pt-3">
            <div class="col-sm-12 d-flex">
                <dfps-textarea _id="txtComments" class="col-sm-12 p-0" formControlName="txtComments" name="txtComments"
                    maxLength="4000" aria-required="true" ariaLabel="Comments"
                    (focusout)="onTrimSpace('txtComments')"></dfps-textarea>
            </div>
        </div>
    </ng-container>

    <div class="row pl-4">
        <div class="d-flex col-sm-12 pt-3 justify-content-end">
            <dfps-button type="submit" size="small" label="Save Changes" _id="saveAgencyHistory"
                [disabled]="requestProcessing"></dfps-button>
        </div>
    </div>
</form>