import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CpaAccountService {
    profilePageApi = environment.profilePageApi;

    readonly CPA_ACCOUNT_LOOKUPS_URL: string = '/PublicLookup/GetFamilyDetailPageValues';
    readonly CPA_ACCOUNT_LOOKUPS_URL_SPANISH: string = '../../../../assets/locales/lookups/es-getFamilyProfilePageLookups.json';
    readonly REGISTER_CPA_URL: string = '/Profile/RegisterCPA';

    public cpaAccountPageLookups: any = null;

    constructor(private http: HttpClient) { }

    async getCpaAccountPageLookups(): Promise<any> {
        if (this.cpaAccountPageLookups != null)
            return this.cpaAccountPageLookups;
        return await this.http.get(`${this.profilePageApi}${this.CPA_ACCOUNT_LOOKUPS_URL}`).toPromise();
    }
    async getCpaAccountPageLookupsES(): Promise<any> {
        return await this.http.get(this.CPA_ACCOUNT_LOOKUPS_URL_SPANISH).toPromise();
    }

    saveCpaAccount(cpaAccountRequest) {
        return this.http.post(`${this.profilePageApi}${this.REGISTER_CPA_URL}`, cpaAccountRequest);
    }

}