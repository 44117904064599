<ng-container *transloco="let cp">
    <div class="row">
        <h1 class="col-sm-12">{{cp('profileFor')}}<span class="notranslate">{{childProfileResponse?.firstName}} </span>
        </h1>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>

    <div class="row pl-3 pt-1" *ngIf="idSibling else notFromSiblingGroup">
        <div class="col-sm-4 pt-2"> </div>
        <div class="text-left col-sm-4 pt-2">
            <span class="notranslate"><strong>{{cp('tareId')}}: {{childProfileResponse?.tareId}}</strong></span>
        </div>
        <div class="d-flex col-sm-4 pt-2 justify-content-end">
            <dfps-button add-button size="small" label="{{cp('viewGrpProfile')}}" _id="viewGroupProfile"
                (click)="viewGroupProfile()"></dfps-button>
        </div>
    </div>
    <ng-template #notFromSiblingGroup>
        <div class="row pl-3 pt-1" *ngIf="isUserLoggedIn else generalSearch">
            <div class="col-6 col-sm-4 pt-2" *ngIf="isRoleFamily">
                <div *ngIf="(childProfileResponse?.inquiryStatus!==''&&childProfileResponse?.inquiryStatus!=null) else inquiryOnChild"
                    class="alert alert-info mb-0" role="alert">
                    {{cp('currentInquiryStatus')}}:{{childProfileResponse?.inquiryStatus}}
                </div>
                <ng-template #inquiryOnChild>
                    <img role="button" tabindex="0" src="/assets/images/inquiry.png" class="img-fluid mr-3"
                        [alt]="cp('inquiryOnChild') + ' ' + cp('icon')" [attr.aria-label]="cp('inquiryOnChild')"
                        (click)="inquiryChild()" (keypress)="inquiryChild()">
                    <div>{{cp('inquiryOnChild')}}</div>
                </ng-template>
            </div>
            <div class="text-left col-6 col-sm-4 pt-2" *ngIf="isRoleFamily">
                <span [ngClass]="childProfileResponse?.isUserBookmark ?'glyphicon glyphicon-bookmark':'pi pi-bookmark'"
                    role="button" tabindex="0"
                    [attr.aria-label]="childProfileResponse?.isUserBookmark ? cp('removeBookmark'):cp('bookmarkChild')"
                    (click)="toggleBookmarkChild()" (keypress)="toggleBookmarkChild()"></span>
                <div>{{childProfileResponse?.isUserBookmark ? cp('removeBookmark'):cp('bookmarkChild')}}</div>
            </div>
            <div
                [ngClass]="isRoleFamily?'d-flex col-sm-4 pt-2 justify-content-end':'d-flex col-sm-12 justify-content-end'">
                <span><strong>{{cp('tareId')}}: {{childProfileResponse?.tareId}}</strong></span>
            </div>
        </div>

        <ng-template #generalSearch>
            <div class="row">
                <div class="d-flex col-sm-4 col-lg-3 pt-3">
                    <dfps-button add-button size="small" label="{{cp('infoOnAdopt')}}"
                        _id="informationGettingStartedAdopt"></dfps-button>
                </div>
                <div class="d-flex col-sm-4 col-lg-3 pt-3">

                </div>
                <div class="d-flex col-sm-4 col-lg-3 pt-3 justify-content-end">
                    <span><strong>{{cp('tareId')}}: {{childProfileResponse?.tareId}}</strong></span>
                </div>
            </div>
        </ng-template>
    </ng-template>
    <div class="row pl-3 pt-4">
        <h2 class="col-sm-12">{{cp('information')}}</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-4">
        <div class="col-lg-6 col-xl-8">
            <div class="row">
                <div class="col-12 col-xl-6 pt-3">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0"><strong>{{cp('name')}}:</strong></p>
                    <p class="col-8 col-sm-8 pl-0 pr-0 mb-0 d-inline-flex notranslate">
                        {{childProfileResponse?.firstName}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0"><strong>{{cp('age')}}:</strong></p>
                    <span>{{childProfileResponse?.age}}</span>
                </div>
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0"><strong>{{cp('gender')}}:</strong></p>
                    <span class="notranslate">{{childProfileResponse?.gender}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0"><strong>{{cp('race')}}:</strong></p>
                    <p class="col-8 col-sm-8 pl-0 pr-0 mb-0 d-inline-flex notranslate">
                        {{childProfileResponse?.race}}</p>
                </div>
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0"><strong>{{cp('ethnicity')}}:</strong></p>
                    <p class="col-8 col-sm-8 pl-0 pr-0 mb-0 d-inline-flex notranslate">
                        {{childProfileResponse?.ethnicity}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0"><strong>{{cp('region')}}:</strong></p>
                    <p class="col-8 col-sm-8 pl-0 pr-0 mb-0 d-inline-flex notranslate">{{childProfileResponse?.region}}
                    </p>
                </div>
                <div class="col-12 col-xl-6 pt-2">
                    <p class="col-4 col-sm-4 boldBody d-inline-flex mb-0"><strong>{{cp('primaryLanguage')}}:</strong>
                    </p>
                    <span class="notranslate">{{childProfileResponse?.primaryLanguage}}</span>
                </div>
            </div>
        </div>
        <div class="d-flex col-sm-12 col-lg-6 col-xl-4 pt-2" *ngIf="childProfileResponse?.primaryMedia">
            <img [src]="childProfileResponse?.primaryMedia" class="img-fluid searchResultsImageSize"
                [alt]="cp('potraitOf') + ' ' + childProfileResponse?.firstName + ' ' + childProfileResponse?.lastName + cp('tareId') + ' ' + childProfileResponse?.tareId">
        </div>
    </div>
    <ng-template [ngIf]="isUserLoggedIn">
        <div class="row pl-3 pt-4">
            <h3 class="col-sm-12 pl-3 h5">{{cp('coordinatorInformationTARE')}}</h3>
        </div>

        <div class="row pl-3">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-12 col-sm-6 pt-3">
                        <p class="col-5 col-sm-5 col-xl-4 boldBody d-inline-flex mb-0"><strong>{{cp('name')}}:</strong>
                        </p>
                        <span class="notranslate"> {{childProfileResponse?.tareCoOrdinator?.firstName}}
                            <ng-template [ngIf]="childProfileResponse?.tareCoOrdinator?.firstName">, </ng-template>
                            {{childProfileResponse?.caseworkerContact?.middleName}}
                            {{childProfileResponse?.tareCoOrdinator?.lastName}}
                        </span>
                    </div>
                    <div class="col-12 col-sm-6 pt-3">
                        <p class="col-5 col-sm-5 col-xl-4 boldBody d-inline-flex mb-0">
                            <strong>{{cp('address')}}:</strong>
                        </p>
                        <span> {{childProfileResponse?.tareCoOrdinator?.fullAddress}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 pt-2">
                        <p class="col-5 col-sm-5 col-xl-4 boldBody d-inline-flex mb-0">
                            <strong>{{cp('emailAddress')}}:</strong>
                        </p>
                        <span> {{childProfileResponse?.tareCoOrdinator?.emailAddress}}</span>
                    </div>
                    <div class="col-sm-6 pt-2">
                        <p class="col-sm-5 col-xl-4 boldBody d-inline-flex mb-0"><strong>{{cp('phoneNumber')}}:</strong>
                        </p>
                        <span> {{childProfileResponse?.tareCoOrdinator?.primaryPhoneNumber}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row pl-3 pt-4">
            <h3 class="col-sm-12 pl-3 h4">{{cp('personalProfile')}}</h3>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>
        <div class="row pl-4 pt-3">
            <p class="col-sm-12 boldBody notranslate"
                [innerHTML]="(activeLangSpanish$ | async) ? (childProfileResponse?.es_ProfileText ? childProfileResponse?.es_ProfileText : childProfileResponse?.profileText) : childProfileResponse?.profileText">
            </p>
        </div>

        <div class="row pl-3 pt-4">
            <h3 class="col-sm-12 pl-3 h4">{{cp('desiredFamilyProfile')}}</h3>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>
        <div class="row pl-4 pt-3">
            <p class="col-sm-12 boldBody notranslate"
                [innerHTML]="(activeLangSpanish$ | async) ? (childProfileResponse?.es_DesiredFamily ? childProfileResponse?.es_DesiredFamily : childProfileResponse?.desiredFamily) : childProfileResponse?.desiredFamily">
            </p>
        </div>
    </ng-template>

    <div class="row pl-3 pt-4">
        <h3 class="col-sm-12 pl-3 h4">{{cp('aboutMe')}}</h3>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-4 pt-3">
        <p class="col-sm-12 boldBody">{{activeLangSpanish$ |async}} </p>
        <p class="col-sm-12 boldBody notranslate"
            [innerHTML]="(activeLangSpanish$ | async) ? (childProfileResponse?.es_TxtTagLine? childProfileResponse?.es_TxtTagLine:childProfileResponse?.txtTagLine) : childProfileResponse?.txtTagLine">
        </p>
    </div>

    <ng-template [ngIf]="childProfileResponse?.allMedia && childProfileResponse?.allMedia?.length > 0">
        <div class="row pl-3 pt-4">
            <h3 class="col-sm-12 pl-3 h4">{{cp('media')}}</h3>
            <div class="col-sm-12">
                <hr aria-hidden="true" />
            </div>
        </div>
        <div class="row pl-4 pt-3">
            <div class="col-sm-12">
                <ng-template ngFor let-media let-index [ngForOf]="this.groupedMedia | keyvalue">
                    <h4 class="h5">{{media?.key}}</h4>
                    <ng-template ngFor let-list let-i="index" [ngForOf]="media?.value">
                        <img *ngIf="list?.mediaUrl!=null" [src]="list?.mediaUrl"
                            class="img-fluid mr-3 mb-2 searchResultsImageSize"
                            [alt]="cp('potraitOf') + childProfileResponse?.firstName + ' ' + childProfileResponse?.lastName + cp('tareId') + ' ' + childProfileResponse?.tareId + ' ' + list?.title">
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </ng-template>
</ng-container>