import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common'; import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { DataShareService } from './data-share.service';
import enLookupsForAPIConversion from '../../../../assets/locales/lookups/enLookupsForAPIConversion.json';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppUtilService {
  public static CYESNO: any[] = [{ code: 'Y', decode: 'Yes' }, { code: 'N', decode: 'No' }];
  public static CYESNO_SPANISH: any[] = [{ code: "N", decode: "No" }, { code: "Y", decode: "Sí" }];

  searchPageApi = environment.searchPageApi;
  profilePageApi = environment.profilePageApi;
  readonly GET_FAMILY_PROFILE_ID: string = '/Profile/GetFamilyIdAndProfilePercentage';
  public familyProfileInfo$: Observable<object>;
  public familyProfileInfoLoaded: boolean;
  private dropdownValue: any = null;
  public isGoogleTranslateSpanish$$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private shareService: DataShareService) { }

  async dropDown(): Promise<any> {
    if (this.dropdownValue != null)
      return this.dropdownValue;
    return await this.http.get(`${this.searchPageApi}`).toPromise();
  }

  async getFamilyProfileInfo() {
    if (!this.familyProfileInfo$) {
      await this.http.get(`${this.profilePageApi}${this.GET_FAMILY_PROFILE_ID}`).toPromise().then(res => {
        this.familyProfileInfoLoaded = true;
        const resp: any = res
        this.familyProfileInfo$ = resp;
        this.shareService.addDataToMap('familyProfileInfo', this.familyProfileInfo$);
        return res;
      })
    }
    return this.familyProfileInfo$;
  }

  getDecode(codeType, code, dropdownType?): string {
    let decode = '';
    let dropDownList: any[];
    // if (code != undefined && code != null)
    switch (dropdownType) {
      case 'custom':
        dropDownList = codeType;
        break;
      default:
        dropDownList = this.dropDown[codeType];
        break;
    }
    dropDownList.forEach(element => {
      if (element.code === code) {
        decode = element.decode;
        return;
      }
    });
    return decode;
  }

  setDateFormat(date, format) {
    const datePipe = new DatePipe('en-US');
    date = datePipe.transform(date, format);
    return date;
  }

  getCopiedText(event: ClipboardEvent): string {
    let copiedText = '';
    if (typeof event.clipboardData === 'undefined' && window['clipboardData'] && window['clipboardData'].getData) {
      copiedText = window['clipboardData'].getData('Text');
    } else if (typeof event.clipboardData !== 'undefined' && event.clipboardData && event.clipboardData.getData) {
      copiedText = event.clipboardData.getData('text/plain');
    }
    return copiedText;
  }

  onTextAreaPaste(event: ClipboardEvent, messageChars, label: string = 'Comment', maxChars: number = 1500) {
    const copiedText = this.getCopiedText(event);
    const textLength = messageChars.value.length - window.getSelection().toString().length;
    if (textLength + copiedText.length > maxChars) {
      alert(label + ' exceeds ' + maxChars + ' character limit.');
      event.preventDefault();
    }
  }

  onTrimSpace(form, formControl) {
    if (form.get(formControl).value) {
      form.get(formControl).setValue(form.get(formControl).value.trim());
    }
  }

  objectValues(obj) {
    return Object.keys(obj).map(itm => obj[itm]);
  }

  addDecodeInResponse(initialResponse, decodePropertyList) {
    const data = JSON.parse(JSON.stringify(initialResponse));
    if (data !== null && typeof data !== 'undefined') {
      data.map((obj) => {
        decodePropertyList.forEach(dClist => {
          switch (dClist.type) {
            case 'str':
              obj[dClist.deCode] = this.getDecode(dClist.codeType, obj[dClist.code], dClist.dropdownType);
              break;
            case 'dtYMD':
              (obj[dClist.code] === 'N/A') ? obj[dClist.deCode] = 'N/A' : obj[dClist.deCode] = this.setDateFormat(obj[dClist.code], 'yyyy/MM/dd');
              break;
          }
        });
      });
    }
    return data;
  }

  groupBy<T>(collection: T[], key: keyof T) {
    const groupedResult = collection.reduce((previous, current) => {

      if (!previous[current[key]]) {
        previous[current[key]] = [] as T[];
      }
      previous[current[key]].push(current);
      return previous;
    }, {} as any);
    return groupedResult
  }

  convertAPIRespTxtToESFromLookups(esCodeType, enValue, enCodeType?): string {
    if (enValue) {
      const enJsonLookup = enLookupsForAPIConversion[enCodeType];
      let esValue = enValue as string;
      let dropDownList: any[];
      dropDownList = esCodeType;
      dropDownList.forEach((element, index) => {
        if (element.code === enJsonLookup[index].code && enJsonLookup[index].decode === enValue) {
          esValue = element.decode;
          return;
        }
      });
      return esValue;
    }
  }

}
