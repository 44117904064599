import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class DynamicCharacteristicsValidators {
    static validateOverallRequiredFields(group: FormGroup) {
        const overallRating = group.controls.overallRating.value;
        if (!overallRating || overallRating === 'NA' || overallRating === '' || overallRating === null) {
            group.controls.overallRating.setValidators(DynamicCharacteristicsValidators.setErrorValidator({
                required: { customFieldName: group.controls.characteristicName.value + ' overall' }
            }));
        } else { group.controls.overallRating.clearValidators(); }

        group.controls.overallRating.updateValueAndValidity({ onlySelf: true });
        return null;
    }

    static setErrorValidator(errorObject: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return errorObject;
        };
    }
}