<ng-container *transloco="let hm">
    <div class="row">
        <div class="col-sm-12">
            <a class="anchorTagColor" [href]="tarePublicHome()"> {{hm('home')}}</a>
        </div>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pt-3">
        <h1 class="col-sm-12"><span class="notranslate">TARE </span>{{hm('dashboard')}}</h1>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row">
        <p class="col-sm-12 pt-3 pl-3 mb-0 boldBody" [innerHTML]="hm('needToCompleteFamilyProfile')"></p>
    </div>

    <div class="row pl-3 pt-3">
        <h2 class="col-sm-12 ">{{hm('notifications')}}</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-3">
        <p class="col-sm-12 pt-3 pl-3 mb-0 boldBody">{{hm('displayAllNotifications')}} </p>
    </div>
    <div class="row pl-2">
        <div class="d-flex col-sm-12 justify-content-center">
            <p-progressSpinner *ngIf="!renderNotificationsTable else displayChildStatusTable"></p-progressSpinner>
        </div>
    </div>
    <ng-template #displayChildStatusTable>
        <div class="row pl-3 pt-2">
            <div class="col-sm-12 pl-4">
                <p-table #dt0 appAccessibilityPTableTare [columns]="notificationTableColumns"
                    [value]="notificationstRes" [paginator]="true" [rowsPerPageOptions]="[5,10,20]" [rows]="5"
                    [totalRecords]="notificationsTableTotalRecords" [first]="0" [alwaysShowPaginator]="false"
                    [autoLayout]="false" class="dfps-data-table-container__ptable">

                    <ng-template pTemplate="caption">
                        <span class="pi pi-filter pull-right"
                            [attr.aria-label]="!notificationTableFilterToggleInd ? hm('filterToggleShow') : hm('filterToggleHide')"
                            role="button" tabindex="0" (click)="filterToggleNotificationTable(dt0)"
                            (keypress)="filterToggleNotificationTable(dt0)"> <span
                                class="sr-only">{{hm('filter')}}</span>
                        </span>
                    </ng-template>

                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th [pSortableColumn]="column.field" *ngFor="let column of columns"> {{column.header}}
                                <p-sortIcon [field]="column.field" [attr.ariaLabel]="hm('activateToSort')"
                                    [attr.ariaLabelDesc]="hm('activateToSortDes')"
                                    [attr.ariaLabelAsc]="hm('activateToSortAsc')">
                                </p-sortIcon>
                            </th>
                        </tr>

                        <tr [hidden]=" !notificationTableFilterToggleInd">
                            <th *ngFor="let col of columns">
                                <span class="sr-only">{{hm('tHeadColumnFilter')}}</span>
                                <input pInputText type="text" attr.aria-label={{getTableColumnAriaLabel(col.header)}}
                                    (input)="dt0.filter($event.target.value, col.field, col.filterMatchMode)"
                                    [value]="dt0.filters[col.field] ? $any(dt0.filters[col.field])?.value : ''" />
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-notificationstRes let-columns="columns">
                        <tr>
                            <td>
                                <a class="notranslate"
                                    [routerLink]="notificationstRes?.siblingGroupId ? ['/child/childSearch/siblingProfile'] : ['/child/childSearch/childProfile']"
                                    [queryParams]="{id:notificationstRes?.siblingGroupId ? notificationstRes.siblingGroupId : notificationstRes.childId }"
                                    [attr.aria-label]="hm('select') + notificationstRes.childName +  hm('linkNavToProfilePage')">{{notificationstRes.childName}}</a>
                            </td>
                            <td>{{notificationstRes.notice}}</td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="2"> {{hm('noRecordsFound')}} </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="paginatorleft" let-state>
                        {{hm('showing',{firstRecord:state.totalRecords < 1 ? 0 : state.first + 1})}}
                            {{hm('of',{pageRecords:state.totalRecords> 0 ? ((state.rows+ state.first) <=
                                state.totalRecords ? (state.rows + state.first) : state.totalRecords) : 0})}}
                                {{hm('entries',{totalRecords:state.totalRecords ? state.totalRecords : 0})}}
                                </ng-template>

                                <ng-template let-state pTemplate="paginatorright">
                                    {{hm('entriesPerPage',{noOfEntries:state.value})}}
                                </ng-template>
                </p-table>
            </div>
        </div>
    </ng-template>

    <div class="row pl-3 pt-3">
        <h2 class="col-sm-12 ">{{hm('bookmarks')}}</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-3">
        <p class="col-sm-12 pt-3 pl-3 mb-0 boldBody">{{hm('displayAllBookmarks')}}
        </p>
    </div>

    <div class="row pl-2">
        <div class="col-sm-12">
            <p-progressSpinner class="d-flex justify-content-center"
                *ngIf="!renderBookmarksTable else displayBookmarksTable"></p-progressSpinner>
        </div>
    </div>
    <ng-template #displayBookmarksTable>
        <div class="row pl-3 pt-2">
            <div class="col-sm-12 pl-4">
                <p-table #dt1 appAccessibilityPTableTare [columns]="bookmarkTableColumns" [value]="bookmarksRes"
                    [paginator]="true" [rows]="5" [totalRecords]="bookmarkTableTotalRecords" [first]="0"
                    [alwaysShowPaginator]="false" [rowsPerPageOptions]="[5,10,20]" [autoLayout]="false"
                    class="dfps-data-table-container__ptable">
                    <ng-template pTemplate="colgroup" let-columns>
                        <colgroup tabindex="-1" id="colGroupId">
                            <col class="column-width" style="width:90px" />
                            <col *ngFor="let col of columns" [style.width]="col.width+'px'" />
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="caption">
                        <span class="pi pi-filter pull-right"
                            [attr.aria-label]="!bookmarkTableFilterToggleInd ? hm('filterToggleShow') : hm('filterToggleHide')"
                            role="button" tabindex="0" (click)="filterToggleBookmarkTable(dt1)"
                            (keypress)="filterToggleBookmarkTable(dt1)"> <span class="sr-only">{{hm('filter')}}</span>
                        </span>
                    </ng-template>

                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th>{{hm('actions')}}</th>
                            <th [pSortableColumn]="column.field" *ngFor="let column of columns"> {{column.header}}
                                <p-sortIcon [field]="column.field" [attr.ariaLabel]="hm('activateToSort')"
                                    [attr.ariaLabelDesc]="hm('activateToSortDes')"
                                    [attr.ariaLabelAsc]="hm('activateToSortAsc')">
                                </p-sortIcon>
                            </th>
                        </tr>

                        <tr [hidden]=" !bookmarkTableFilterToggleInd">
                            <th><span class="sr-only">{{hm('tHeadColumnFilter')}}</span></th>
                            <th *ngFor="let col of columns">
                                <span class="sr-only">{{hm('tHeadColumnFilter')}}</span>
                                <input pInputText type="text" attr.aria-label={{getTableColumnAriaLabel(col.header)}}
                                    (input)="dt1.filter($event.target.value, col.field, col.filterMatchMode)"
                                    [value]="dt1.filters[col.field] ? $any(dt1.filters[col.field])?.value : ''" />
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-bookmarksRes let-columns="columns">
                        <tr>
                            <td>
                                <span class="pi pi-trash" role="button" tabindex="0"
                                    [attr.aria-label]="hm('deleteBookMarkFor',{name:bookmarksRes?.childName})"
                                    (click)="deletebookmark(bookmarksRes)" (keypress)="deletebookmark(bookmarksRes)">
                                    <span style="font-size:14px"> {{hm('delete')}}</span>
                                </span>
                            </td>
                            <td>
                                <img *ngIf="bookmarksRes.txtBlobUrl && bookmarksRes.txtBlobUrl!=null"
                                    class="img-fluid searchResultsImageSize" [src]="bookmarksRes.txtBlobUrl"
                                    [alt]="'Portrait of ' + bookmarksRes.childName">
                            </td>
                            <td>
                                <a class="notranslate"
                                    [routerLink]="(bookmarksRes?.idSiblingGroup && bookmarksRes?.idSiblingGroup!==0) ? ['/child/childSearch/siblingProfile'] : ['/child/childSearch/childProfile']"
                                    [queryParams]="{id:bookmarksRes?.idSiblingGroup ? bookmarksRes.idSiblingGroup : bookmarksRes.idChild }"
                                    [attr.aria-label]="hm('select') + ' ' + bookmarksRes.childName + ' ' + hm('linkNavToProfilePage')">{{bookmarksRes.childName}}</a>
                            </td>
                            <td>{{bookmarksRes.dtCreated | date:'MM/dd/yyyy'}}</td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="4">{{hm('noRecordsFound')}} </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="paginatorleft" let-state>
                        {{hm('showing',{firstRecord:state.totalRecords < 1 ? 0 : state.first + 1})}}
                            {{hm('of',{pageRecords:state.totalRecords> 0 ? ((state.rows+ state.first) <=
                                state.totalRecords ? (state.rows + state.first) : state.totalRecords) : 0})}}
                                {{hm('entries',{totalRecords:state.totalRecords ? state.totalRecords : 0})}}
                                </ng-template>

                                <ng-template let-state pTemplate="paginatorright">
                                    {{hm('entriesPerPage',{noOfEntries:state.value})}}
                                </ng-template>
                </p-table>
            </div>
        </div>
    </ng-template>

    <div class="row pl-3 pt-3">
        <h2 class="col-sm-12 ">{{hm('inquiries')}}</h2>
        <div class="col-sm-12">
            <hr aria-hidden="true" />
        </div>
    </div>
    <div class="row pl-3">
        <p class="col-sm-12 pt-3 pl-3 mb-0 boldBody">{{hm('displayAllInquiries')}}
        </p>
    </div>
    <div class="row pl-2">
        <div class="d-flex col-sm-12 justify-content-center">
            <p-progressSpinner *ngIf="!renderInquiriesTable else displayInquiriesTable"></p-progressSpinner>
        </div>
    </div>
    <ng-template #displayInquiriesTable>
        <div class="row pl-3 pt-2">
            <div class="col-sm-12 pl-4">
                <p-table #dt2 appAccessibilityPTableTare [columns]="inquiriesTableColumn" [value]="inquiriesRes"
                    [paginator]="true" [rows]="5" [totalRecords]="inquiriesTableTotalRecords" [first]="0"
                    [alwaysShowPaginator]="false" [rowsPerPageOptions]="[5,10,20]" [autoLayout]="false"
                    class="dfps-data-table-container__ptable">

                    <ng-template pTemplate="caption">
                        <span class="pi pi-filter pull-right"
                            [attr.aria-label]="!inquiriesTableFilterToggleInd ? hm('filterToggleShow') : hm('filterToggleHide')"
                            role="button" tabindex="0" (click)="filterToggleinquiryTable(dt2)"
                            (keypress)="filterToggleinquiryTable(dt2)"> <span class="sr-only">{{hm('filter')}}</span>
                        </span>
                    </ng-template>

                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th [pSortableColumn]="column.field" *ngFor="let column of columns">
                                {{column.header}}<p-sortIcon [field]="column.field"
                                    [attr.ariaLabel]="hm('activateToSort')"
                                    [attr.ariaLabelDesc]="hm('activateToSortDes')"
                                    [attr.ariaLabelAsc]="hm('activateToSortAsc')">
                                </p-sortIcon>
                            </th>
                        </tr>

                        <tr [hidden]=" !inquiriesTableFilterToggleInd">
                            <th *ngFor="let col of columns">
                                <span class="sr-only">{{hm('tHeadColumnFilter')}}</span>
                                <input pInputText type="text" attr.aria-label={{getTableColumnAriaLabel(col.header)}}
                                    (input)="dt2.filter($event.target.value, col.field, col.filterMatchMode)"
                                    [value]="dt2.filters[col.field] ? $any(dt2.filters[col.field])?.value : ''" />
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-inquiriesRes let-columns="columns">
                        <tr>
                            <td>
                                <a [routerLink]="['/home/dashboard/inquiryDetails']"
                                    [queryParams]="{inquiryId:inquiriesRes?.inquiryId }"
                                    [attr.aria-label]="hm('dashBoardInquiryLinkLabel',{status:inquiriesRes.status, inquiryId:inquiriesRes.inquiryId})">{{inquiriesRes.status}}</a>
                            </td>
                            <td>{{inquiriesRes.submitDate | date:'MM/dd/yyyy'}}</td>
                            <td>
                                <a class="notranslate"
                                    [routerLink]="inquiriesRes?.siblingGroupId ? ['/child/childSearch/siblingProfile'] : ['/child/childSearch/childProfile']"
                                    [queryParams]="{id:inquiriesRes?.siblingGroupId ? inquiriesRes.siblingGroupId : inquiriesRes.childId }"
                                    [attr.aria-label]="hm('select') + ' ' + inquiriesRes.childName + ' ' +
                                    hm('linkNavToProfilePage')">{{inquiriesRes.childName}}</a>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="3"> {{hm('noRecordsFound')}} </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="paginatorleft" let-state>
                        {{hm('showing',{firstRecord:state.totalRecords < 1 ? 0 : state.first + 1})}}
                            {{hm('of',{pageRecords:state.totalRecords> 0 ? ((state.rows+ state.first) <=
                                state.totalRecords ? (state.rows + state.first) : state.totalRecords) : 0})}}
                                {{hm('entries',{totalRecords:state.totalRecords ? state.totalRecords : 0})}}
                                </ng-template>

                                <ng-template let-item pTemplate="paginatorright">
                                    {{hm('entriesPerPage',{noOfEntries:item.value})}}
                                </ng-template>
                </p-table>
            </div>
        </div>
    </ng-template>
</ng-container>