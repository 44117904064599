import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  profilePageApi = environment.profilePageApi;

  readonly GET_NOTIFICATIONS_BY_FAMILY_ID_URL: string = '/Profile/GetNotificationsByFamilyID?familyId=';
  readonly GET_BOOKMARKS_BY_FAMILY_ID_URL: string = '/Profile/GetBookMarksByFamilyId?familyId=';
  readonly GET_INQUIRIES_FOR_FAMILY_URL: string = '/Profile/GetAllInquiriesForFamily?familyId=';
  readonly GET_INQUIRY_DETAILS_URL: string = '/Profile/GetInquiryDetailsbyID?inquiryId=';
  constructor(private http: HttpClient) { }

  getNotificationsRequest(familyId) {
    return this.http.get<any>(`${this.profilePageApi}${this.GET_NOTIFICATIONS_BY_FAMILY_ID_URL}${familyId}`);
  }

  getBookmarks(familyId) {
    return this.http.get<any>(`${this.profilePageApi}${this.GET_BOOKMARKS_BY_FAMILY_ID_URL}${familyId}`);
  }

  getInquiries(familyId) {
    return this.http.get<any>(`${this.profilePageApi}${this.GET_INQUIRIES_FOR_FAMILY_URL}${familyId}`);
  }

  getInquiryDetails(inquiryId) {
    return this.http.get<any>(`${this.profilePageApi}${this.GET_INQUIRY_DETAILS_URL}${inquiryId}`);
  }
}
