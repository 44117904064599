<div class="row">
    <h1 class="col-sm-12">Family Profile</h1>
    <div class="col-sm-12">
        <hr aria-hidden="true" />
    </div>
</div>

<div class="row pl-3 pt-4" *ngIf="familyProfileDetails?.pendingSections.length > 0">
    <div class="alert alert-warning py-0 mb-0" role="alertdialog" aria-live="polite">
        <h2>The Section(s) Below Needs Attention:</h2>
        <p>Please fill all the required feilds from the below sections to complete the profile.</p>
        <ul class="mb-1" *ngFor="let listItems of familyProfileDetails?.pendingSections" [ngSwitch]="listItems.key">
            <li *ngSwitchCase="'familyInformation'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyInfo" class="alert-link">{{listItems.value}}</a>
            </li>
            <li *ngSwitchCase="'parentalExpectations'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/parentalExpectations" class="alert-link">{{listItems.value}}</a>
            </li>
            <li *ngSwitchCase="'adoptionInterest'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/adoptionInterests" class="alert-link">{{listItems.value}}</a>
            </li>
            <li *ngSwitchCase="'agencyHome'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/agencyHistory" class="alert-link">{{listItems.value}}</a>
            </li>
            <li *ngSwitchCase="'behavioral'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/behavioral"
                    class="alert-link">{{listItems.value}}</a>
            </li>
            <li *ngSwitchCase="'physical'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/emotional"
                    class="alert-link">{{listItems.value}}</a>
            </li>
            <li *ngSwitchCase="'emotional'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/physical"
                    class="alert-link">{{listItems.value}}</a>
            </li>
            <li *ngSwitchCase="'medical'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/medical"
                    class="alert-link">{{listItems.value}}</a>
            </li>
            <li *ngSwitchCase="'developmental'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/developmental"
                    class="alert-link">{{listItems.value}}</a>
            </li>
            <li *ngSwitchCase="'learning'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/learning"
                    class="alert-link">{{listItems.value}}</a>
            </li>
            <li *ngSwitchCase="'riskFactor'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/riskFactor"
                    class="alert-link">{{listItems.value}}</a>
            </li>
            <li *ngSwitchCase="'hobbies'">
                <a (keydown.space)="$event.preventDefault();$event.target.click()"
                    routerLink="/familyProfile/familyChildCharacteristicPreferences/interestHobbies"
                    class="alert-link">{{listItems.value}}</a>
            </li>
        </ul>
    </div>

</div>

<div class="row pl-3">
    <div class="d-flex col-sm-6 col-md-4 pt-3">
        <p class="boldBody d-inline-flex pb-0"><strong>Profile is currently
                {{familyProfileDetails?.profilePercent}}% complete</strong></p>
    </div>
    <div class="d-flex col-sm-6 col-md-4 pt-3">
        <p class="boldBody d-inline-flex pb-0"><strong class="mr-1"> Home Screening Status: </strong>
            {{familyProfileDetails?.homeScreeningStatus}}</p>
    </div>
    <div class="d-flex col-sm-12 col-md-4 pt-3 requiredDisplay justify-content-end"
        *ngIf="characteristicType?.code != 'HB'">
        <span class="sr-only">Red asterisk is </span>required field
    </div>
</div>

<div class="row pl-3 pt-4" *ngIf="successMsg" id="familyProfileSuccessMsg">
    <div class="d-flex col-sm-12 pt-3 alert alert-success" role="alert">
        <h2 class="alert-heading">Successfully Saved on {{successMsg}}</h2>
    </div>
</div>