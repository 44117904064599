import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChildRoutingModule } from './child-routing.module';
import { DfpsButton, DfpsCheckBox, DfpsConfirm, DfpsDataTable, DfpsDataTable1, DfpsDataTableLazyload, DfpsDataTableLazyload1, DfpsDirtyCheck, DfpsFormValidation, DfpsInput, DfpsMultiSelect, DfpsProgressBar, DfpsSelect, DfpsTextarea } from 'dfps-web-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChildSearchComponent } from './child/child-search-data-view/child-search.component';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { ChildProfileComponent } from './child/child-profile/child-profile.component';
import { SharedModule } from '@shared/shared.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InquiryComponent } from './child/inquiry/inquiry.component';
import { SiblingProfileComponent } from './child/sibling-profile/sibling-profile.component';
import { DropdownModule } from 'primeng/dropdown';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { CpaSpecialNeedsComponent } from './child/cpa-special-needs/cpa-special-needs.component';


@NgModule({
  declarations: [
    ChildSearchComponent,
    ChildProfileComponent,
    SiblingProfileComponent,
    InquiryComponent,
    CpaSpecialNeedsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    DfpsButton, DfpsConfirm, DfpsDirtyCheck, DfpsFormValidation, DfpsInput, DfpsTextarea,
    DfpsCheckBox,
    ChildRoutingModule,
    DfpsProgressBar,
    DfpsSelect,
    DfpsMultiSelect,
    DfpsDataTableLazyload,
    DfpsDataTableLazyload1,
    DfpsDataTable,
    DfpsDataTable1,
    TableModule,
    DataViewModule,
    DropdownModule,
    ProgressSpinnerModule,
    TranslocoRootModule
  ]
})
export class ChildModule { }
