import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSizeConverter'
})
export class FileSizeConverterPipe implements PipeTransform {

  transform(size: number, byteType?: string): any {
    let convertedFileSize;
    switch (byteType) {
      case 'MB':
        convertedFileSize = (size / (1024 * 1024)).toFixed(2) + byteType;
        break;
      default:
        convertedFileSize = (size / (1024)).toFixed(2) + 'KB';
        break;
    }
    return convertedFileSize;
  }
}
