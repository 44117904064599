import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'environments/environment';
import { AccountInfo } from '@azure/msal-browser';
import { DataShareService } from '@shared/services/data-share.service';

@Component({
  selector: 'app-access-error',
  template: `
  <div class="row" role="alert">
    <div class="col-sm-12 align-items-center">
        <h1 class="alert alert-danger"><i class="fa fa-ban me-2"></i> Access Error</h1>
    </div>
    <div class="col-sm-12 ps-4 mt-2">
        <p>You currently don't have access to this application. If you feel you are getting this message in
            error, For further assistance with this issue, please email
            <a class="anchorTagColor ml-2"
            [href]="'mailto:'+ tareMailBox +'?subject=TARE Public, Access Error for user ' + userInfo?.name + ' ' + userInfo?.username
            + '&body=Hello.%0D%0D Name: ' + userInfo?.name
            +'%0D User Name: ' + userInfo?.username
            +'%0D Email: ' + userInfo?.idTokenClaims?.emails[0]
            +'%0D Role: ' + userInfo?.idTokenClaims?.extension_Roles
            +'%0D Family Id: ' + familyProfileInfo?.familyId
            +'%0D User Logged-in Time: ' + userLoggedInTime
            +'%0D%0D User might be missing Family Id or User Role'"
            target="_blank">TARE@dfps.texas.gov</a>
        .
        </p>


    </div>
  </div>`
})
export class AccessErrorComponent {

  tareMailBox: string = environment.tareMailBox;
  userInfo: AccountInfo;
  userLoggedInTime = new Date();
  familyProfileInfo = this.shareService.getDataFromMap('familyProfileInfo');

  constructor(
    private authService: MsalService,
    private shareService: DataShareService,) {
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.userInfo = (this.authService.instance?.getAllAccounts()[0] as AccountInfo);
      this.userLoggedInTime = new Date(this.userInfo?.idTokenClaims?.auth_time * 1000);
    }
  }
}
